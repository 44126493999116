import { FormEvent, FunctionComponent, useEffect, useMemo } from "react";
import { FormGrid, FormItem } from "../../../../components/layout/formGrid";
import { Input } from "../../../../components/common/input";
import { Translation } from "../../../../components/dictionary/translation";
import { useUser } from "../../../../store/api/user/hooks";
import { Loader } from "../../../../components/common/loader";
import {
  useEditedPersonalInfo,
  useEditingPersonalInfo,
} from "../../../../store/slices/profile/selectors";
import { useAppDispatch } from "../../../../store";
import { profileActions } from "../../../../store/slices/profile/slice";
import { ActionGroup } from "../../../../components/layout/actionGroup";
import { Button } from "../../../../components/common/button";
import { useSubmitPersonalInfoMutation } from "../../../../store/api/user/endpoints";
import { ErrorType } from "../../../../types/errors";

export const PersonalDataForm: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { data: user } = useUser();
  const editedPersonalInfo = useEditedPersonalInfo();
  const editing = useEditingPersonalInfo();
  const [submit, { isLoading, isSuccess, error }] =
    useSubmitPersonalInfoMutation();

  const errors = useMemo(
    () =>
      (error && "data" in error ? error.data : undefined) as
        | ErrorType<"name" | "phone">
        | undefined,
    [error]
  );

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    submit(editedPersonalInfo);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(profileActions.setEditingPersonalInfo(false));
    }
  }, [isSuccess, dispatch]);

  if (!user) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormGrid>
        <FormItem span={2}>
          <Input
            name="name"
            label={<Translation k={"common.name"} />}
            value={editedPersonalInfo.name || user.personalInfo.name}
            onChange={(event) => {
              if (event.target.value === user.personalInfo.name) {
                dispatch(profileActions.setEditedName(null));
              } else {
                dispatch(profileActions.setEditedName(event.target.value));
              }
            }}
            disabled={!editing}
            error={editing ? errors?.errors?.["name"] : undefined}
          />
        </FormItem>
        <FormItem span={2}>
          <Input
            name="phone"
            inputMode={"tel"}
            label={<Translation k={"common.phone"} />}
            value={editedPersonalInfo.phone || user.personalInfo.phone}
            disabled={!editing}
            onChange={(event) => {
              if (event.target.value === user.personalInfo.phone) {
                dispatch(profileActions.setEditedPhone(null));
              } else {
                dispatch(profileActions.setEditedPhone(event.target.value));
              }
            }}
            error={editing ? errors?.errors?.["phone"] : undefined}
          />
        </FormItem>
        {editing ? (
          <FormItem span={2}>
            <ActionGroup end>
              <Button
                type="submit"
                loading={isLoading}
                disabled={!editedPersonalInfo.name && !editedPersonalInfo.phone}
              >
                <Translation k={"common.save"} />
              </Button>
            </ActionGroup>
          </FormItem>
        ) : null}
        <FormItem span={2}>
          <Input
            name="email"
            type="email"
            label={<Translation k={"common.email"} />}
            value={user.personalInfo.email}
            disabled
          />
        </FormItem>
      </FormGrid>
    </form>
  );
};
