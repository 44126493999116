import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import styles from "./optionGroup.module.css";

export interface OptionGroupProps extends HTMLAttributes<HTMLFieldSetElement> {
  legend?: string | ReactNode;
  row?: boolean;
}

export const OptionGroup: FunctionComponent<OptionGroupProps> = ({
  legend,
  children,
  className,
  row,
  ...rest
}) => {
  return (
    <fieldset className={`${styles.optionGroup} ${className}`} {...rest}>
      {legend ? <legend className={`${styles.optionGroup__legend}`}>{legend}</legend> : null}
      <div className={`${styles.optionGroup__grid} ${row ? styles.optionGroup__grid_row : ''}`}>
        {children}
      </div>
    </fieldset>
  );
};
