import { FunctionComponent, HTMLAttributes } from "react";
import styles from './label.module.css'
import { useModifiers } from "../../../hooks/useModifiers";

export interface LabelProps extends HTMLAttributes<HTMLSpanElement> {}

export const Label: FunctionComponent<LabelProps> = ({children, className, ...rest}) => {
  const mods = useModifiers(styles, 'label', {}, {withBaseSelector: true, inherited: className})

  return <span className={`${mods}`}>
    {children}
  </span>
}