import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  selectedDiet: string | null;
} = {
  selectedDiet: null,
};

export const menuSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSelectedDiet: (state, action: PayloadAction<string | null>) => {
      state.selectedDiet = action.payload;
    },
  },
});

export const menuActions = menuSlice.actions;
