import { useText } from "components/dictionary/translation";
import { FunctionComponent } from "react";
import logo from "images/logo.svg";
import styles from "./logo.module.css";
import { Link } from "react-router-dom";

export const Logo: FunctionComponent = () => {
  const title = useText("title");
  return (
    <Link to="/" className={styles.logo}>
      <img src={logo} alt={title} />
    </Link>
  );
};
