import styles from "./deliveryInfoCard.module.css";
import { FunctionComponent } from "react";
import { OrderType } from "../../../types/order";

export const DeliveryInfoCard: FunctionComponent<{
  address: string;
  personalInfo: OrderType["personalInfo"];
  comment?: string | null;
}> = ({ address, personalInfo, comment }) => {
  return (
    <div className={styles.deliveryInfoCard}>
      <div>
        <b>{personalInfo.name}</b> ({personalInfo.email})
      </div>
      <div>{address}</div>
      {comment ? <div className={styles.deliveryInfoCard__comment}>{comment}</div> : null}
    </div>
  );
};
