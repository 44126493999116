import { PriceType } from "../types/price";

export const addPrices = (
  price: PriceType,
  secondPrice: PriceType
): PriceType => {
  return {
    ...price,
    value: price.value + secondPrice.value,
  };
};

export const multiplyPrice = (price: PriceType, multiplier: number) => {
  return {
    ...price,
    value: price.value * multiplier,
  };
};
