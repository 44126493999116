import { api } from "../api";
import { OrderFormType } from "../../slices/order/types";
import { OrderType } from "../../../types/order";

export const orderEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    submitOrder: build.mutation<
      {
        orderId: string;
        success: boolean;
      },
      OrderFormType
    >({
      query: (diet) => {
        return {
          url: `order`,
          body: diet,
          method: "POST",
        };
      },
    }),
    getOrder: build.query<OrderType, { id: string }>({
      query: ({ id }) => {
        return {
          url: `order/${id}`,
          method: "GET",
        };
      },
      providesTags: ["OrderSingle"],
    }),
  }),
  overrideExisting: true,
});

export const { useSubmitOrderMutation, useGetOrderQuery } = orderEndpoints;
