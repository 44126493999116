import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../index";

const rawBaseQuery = fetchBaseQuery({
  baseUrl: "https://api.fit.foodclub.lv/app/",
});

export type ApiBaseQueryType = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
>;

const dynamicBaseQuery: ApiBaseQueryType = async (args, api, extraOptions) => {
  const state = api.getState() as RootState;
  const token = state.common.token;

  const locale = state.common.locale;

  const newHeaders:
    | Headers
    | string[][]
    | Record<string, string | undefined>
    | undefined = {};

  newHeaders["Content-Type"] = "application/json";
  newHeaders["Accept"] = "application/json";
  newHeaders["locale"] = locale;

  if (token) {
    newHeaders.Authorization = `Bearer ${token}`;
  }

  const adjustedArgs =
    typeof args === "string"
      ? args
      : {
          ...args,
          headers: { ...args.headers, ...newHeaders },
        };
  return rawBaseQuery(adjustedArgs, api, extraOptions);
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
  tagTypes: ["User", "OrderSingle", "Range"],
});
