import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Locations,
  OrderErrorType,
  OrderFormType,
  OrderPaymentType,
} from "./types";
import { WeekSubset } from "../../../types/calendar";
import { PaymentMethods } from "../../../types/payment";

const initialState: {
  order: OrderFormType;
  errors: OrderErrorType | null;
  payment: OrderPaymentType | null;
} = {
  order: {
    diets: [],
    range: {
      weeks: 2,
      weekSubset: WeekSubset.all,
    },
    delivery: {
      location: null,
      address: {
        city: "",
        address: "",
      },
      comment: "",
    },
    personalInfo: {
      name: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
      agree: false,
    },
  },
  payment: null,
  errors: null,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setError(state, action: PayloadAction<OrderErrorType | null>) {
      state.errors = action.payload;
    },
    addDiet: (
      state,
      action: PayloadAction<string | { id: string; unique?: boolean }>
    ) => {
      if (typeof action.payload === "string") {
        state.order.diets.push({ id: action.payload, comment: null });
      } else {
        if (action.payload.unique) {
          if (!state.order.diets.length) {
            state.order.diets.push({ id: action.payload.id, comment: null });
          }
        } else {
          state.order.diets.push({ id: action.payload.id, comment: null });
        }
      }
    },
    changeDiet: (
      state,
      action: PayloadAction<{ index: number; newId: string }>
    ) => {
      if (state.order.diets[action.payload.index]) {
        state.order.diets[action.payload.index].id = action.payload.newId;
      }
    },
    removeDiet: (state, action: PayloadAction<number>) => {
      state.order.diets.splice(action.payload, 1);
    },
    resetDiets: (state) => {
      state.order.diets = [];
    },
    setDietComment: (
      state,
      action: PayloadAction<{ index: number; comment: string | null }>
    ) => {
      state.order.diets[action.payload.index].comment = action.payload.comment;
    },
    setWeeks: (state, action: PayloadAction<number>) => {
      state.order.range.weeks = action.payload;
    },
    setWeekSubset: (state, action: PayloadAction<WeekSubset>) => {
      state.order.range.weekSubset = action.payload;
    },
    setComment: (state, action: PayloadAction<string>) => {
      state.order.delivery.comment = action.payload;
    },
    setLocation: (state, action: PayloadAction<Locations>) => {
      state.order.delivery.location = action.payload;
    },
    setAddressCity: (state, action: PayloadAction<string>) => {
      state.order.delivery.address.city = action.payload;
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.order.delivery.address.address = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.order.personalInfo.name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.order.personalInfo.email = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.order.personalInfo.phone = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.order.personalInfo.password = action.payload;
    },
    setPasswordConfirmation: (state, action: PayloadAction<string>) => {
      state.order.personalInfo.password_confirmation = action.payload;
    },
    setAgree: (state, action: PayloadAction<boolean>) => {
      state.order.personalInfo.agree = action.payload;
    },
    setPaymentMethod: (state, action: PayloadAction<PaymentMethods>) => {
      if (state.payment) {
        state.payment.method = action.payload;
        if (action.payload !== PaymentMethods.bank) {
          state.payment.bank = null;
        }
      } else {
        state.payment = {
          method: action.payload,
          bank: null,
        };
      }
    },
    setPaymentBank: (state, action: PayloadAction<string>) => {
      if (state.payment) {
        state.payment.bank = action.payload;
      }
    },
  },
});

export const orderActions = orderSlice.actions;
