import { api } from "../api";

export const dietsEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    confirmEmail: build.query<
      { token: string; orderId?: string },
      { id: string; expires: string; signature: string }
    >({
      query: ({ id, ...rest }) => ({
        url: `email/verify/${id}`,
        params: rest,
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useLazyConfirmEmailQuery } = dietsEndpoints;
