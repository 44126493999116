import { FunctionComponent, ReactNode, useEffect, useMemo } from "react";
import { PageSection } from "../../components/layout/pageSection";
import { Translation } from "../../components/dictionary/translation";
import { Title } from "../../components/layout/title";
import { Navigate, useParams } from "react-router-dom";
import { useGetOrderQuery } from "../../store/api/order/endpoints";
import { Loader } from "../../components/common/loader";
import { InfoCard } from "../../components/cards/info";
import { OrderStatus } from "../../types/order";
import { OrderPaymentBlock } from "./paymentBlock";
import { OrderInfoCard } from "../../components/cards/orderInfoCard";
import { usePriceFormatter } from "../../hooks/useFormattedPrice";
import { orderActions } from "../../store/slices/order/slice";
import { useAppDispatch } from "../../store";

export const OrderSingle: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const priceFormatter = usePriceFormatter();
  const { data: order, isFetching } = useGetOrderQuery(
    { id: id || "" },
    { skip: !id }
  );

  useEffect(() => {
    if (!isFetching && order && order.paymentMethods?.default) {
      dispatch(orderActions.setPaymentMethod(order.paymentMethods.default));
    }
  }, [dispatch, isFetching, order]);

  const message: ReactNode = useMemo(() => {
    if (order) {
      switch (order.status) {
        case OrderStatus.PendingAddress: {
          return (
            <InfoCard warning>
              <Translation
                k={`order.statusMessage.${OrderStatus.PendingAddress}`}
              />
            </InfoCard>
          );
        }
        case OrderStatus.PaymentFailed:
        case OrderStatus.PendingPayment: {
          return (
            <InfoCard>
              <Translation
                k={`order.statusMessage.${OrderStatus.PendingPayment}`}
              />
            </InfoCard>
          );
        }
        case OrderStatus.Success: {
          return (
            <InfoCard>
              <Translation k={`order.statusMessage.${OrderStatus.Success}`} />
            </InfoCard>
          );
        }
        case OrderStatus.CacheAwaiting: {
          return (
            <InfoCard warning>
              <Translation k={`order.status.${OrderStatus.CacheAwaiting}`} />
              {":"}
              <b>&nbsp;{priceFormatter(order.sum.total, { noUnits: true })}</b>
            </InfoCard>
          );
        }
        case OrderStatus.Cancelled: {
          return (
            <InfoCard danger>
              <Translation k={`order.statusMessage.${OrderStatus.Cancelled}`} />
            </InfoCard>
          );
        }
        case OrderStatus.RejectedAddress: {
          return (
            <InfoCard danger>
              <Translation
                k={`order.statusMessage.${OrderStatus.RejectedAddress}`}
              />
            </InfoCard>
          );
        }
      }
    }
    return null;
  }, [priceFormatter, order]);

  if (!id) {
    return <Navigate to={"/profile"} replace />;
  }

  if (!order) {
    return <Loader />;
  }

  return (
    <>
      <PageSection
        title={
          <Title>
            <Translation k={"order.order"} /> #{order.id}
          </Title>
        }
      >
        {message}
        <OrderInfoCard order={order} />
        <OrderPaymentBlock order={order} />
      </PageSection>
    </>
  );
};
