import styles from "./button.module.css";
import { ButtonHTMLAttributes, FunctionComponent, ReactNode } from "react";
import { useModifiers } from "hooks/useModifiers";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Loader } from "../loader";

type ButtonPropsType = {
  icon?: ReactNode;
  fullWidth?: boolean;
  wireframe?: boolean;
  vertical?: boolean;
  themeOutline?: boolean;
  loading?: boolean;
  text?: boolean;
  darkBackground?: boolean;
};

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonPropsType {}
export interface LinkProps extends RouterLinkProps, ButtonPropsType {}

export const Button: FunctionComponent<ButtonProps> = ({
  icon,
  children,
  className,

  fullWidth,
  wireframe,
  vertical,
  themeOutline,
  loading,
  text,
  disabled,
  darkBackground,
  ...rest
}) => {
  const mods = useModifiers(styles, "button", {
    fullWidth,
    wireframe,
    vertical,
    themeOutline,
    text,
    loading,
    darkBackground,
    iconOnly: !!icon && !children,
  });

  return (
    <button
      className={`${styles.button} ${mods} ${className}`}
      disabled={disabled || loading}
      {...rest}
    >
      <span className={styles.button__content}>
        {icon ? <span className={styles.button__icon}>{icon}</span> : null}
        {children ? <span>{children}</span> : null}
      </span>
      <span className={styles.button__loader}>
        <Loader />
      </span>
    </button>
  );
};

export const Link: FunctionComponent<LinkProps> = ({
  icon,
  children,
  className,

  fullWidth,
  wireframe,
  vertical,
  themeOutline,
  loading,
  text,
  ...rest
}) => {
  const mods = useModifiers(styles, "button", {
    fullWidth,
    wireframe,
    vertical,
    themeOutline,
    loading,
    text,
  });

  return (
    <RouterLink className={`${styles.button} ${mods} ${className}`} {...rest}>
      <span className={styles.button__content}>
        {icon ? <span className={styles.button__icon}>{icon}</span> : null}
        <span>{children}</span>
      </span>
    </RouterLink>
  );
};
