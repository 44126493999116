import { api } from "../api";
import { RangeType } from "./types";
import { WeekSubset } from "../../../types/calendar";

export const rangeEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    getRange: build.query<
      RangeType,
      { weeks: number; weekSubset: WeekSubset; diets: string[] }
    >({
      query: ({ weeks, weekSubset, diets }) => {
        const urlSearchParams = new URLSearchParams();
        urlSearchParams.append("weeks", weeks.toString());
        urlSearchParams.append("weekSubset", weekSubset.toString());
        diets.forEach((diet) => urlSearchParams.append("diets[]", diet));
        return {
          url: `range?${urlSearchParams.toString()}`,
        };
      },
      providesTags: ["Range"],
    }),
  }),
  overrideExisting: true,
});

export const { useGetRangeQuery } = rangeEndpoint;
