import {
  ReactNode,
  TextareaHTMLAttributes,
  VoidFunctionComponent,
} from "react";
import { Label } from "../label";
import { v4 as uuid } from "uuid";
import styles from "./textarea.module.css";
import { ErrorPropType, Errors } from "../errors";
import { useModifiers } from "../../../hooks/useModifiers";

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: ReactNode;
  error?: ErrorPropType;
}

export const TextArea: VoidFunctionComponent<TextAreaProps> = ({
  label,
  id = uuid(),
  value,
  error,
  ...rest
}) => {
  const mods = useModifiers(
    styles,
    "textarea",
    { error: !!error },
    { withBaseSelector: true, inherited: rest.className }
  );

  return (
    <div className={mods}>
      {label ? (
        <label htmlFor={id}>
          <Label>{label}</Label>
        </label>
      ) : null}
      <div className={styles.textarea__wrapper}>
        <textarea
          className={styles.textarea__field}
          rows={1}
          value={value}
          {...rest}
        />
        <div className={styles.textarea__ghost} aria-hidden={"true"}>
          {value + " "}
        </div>
      </div>

      <Errors error={error} />
    </div>
  );
};
