import { PriceType } from "../types/price";
import { useTranslator } from "./useTranslatable";
import { useCallback } from "react";

export type PriceFormatterOptions = {
  noUnits?: boolean;
};

export const usePriceFormatter = () => {
  const translator = useTranslator();
  return useCallback(
    (
      price?: PriceType,
      options?: PriceFormatterOptions
    ): string | undefined => {
      if (!price) return undefined;
      let formattedPrice = new Intl.NumberFormat(price.locale, {
        style: "currency",
        currency: price.currency.abbreviation,
      }).format(price.value);
      if (price.units && !options?.noUnits) {
        formattedPrice += `/${translator(price.units)}`;
      }
      return formattedPrice;
    },
    [translator]
  );
};

export const useFormattedPrice = (
  price?: PriceType,
  options?: PriceFormatterOptions
): string | undefined => {
  const formatter = usePriceFormatter();
  return formatter(price, options);
};
