import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import styles from "./pageSection.module.css";
import { ActionGroup } from "../actionGroup";

export interface PageSectionProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title?: ReactNode;
  actions?: ReactNode;
}

export const PageSection: FunctionComponent<PageSectionProps> = ({
  title,
  children,
  className,
  actions,
  ...rest
}) => {
  return (
    <div className={`${styles.pageSection} ${className}`} {...rest}>
      {title || actions ? (
        <div className={styles.pageSection__header}>
          {title ? (
            <div className={styles.pageSection__title}>{title}</div>
          ) : null}
          {actions ? <ActionGroup>{actions}</ActionGroup> : null}
        </div>
      ) : null}

      <div className={styles.pageSection__content}>{children}</div>
    </div>
  );
};
