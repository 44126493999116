import { useOrderDiets, useOrderRange } from "../../slices/order/selectors";
import { useGetRangeQuery } from "./endpoints";

export const useRange = () => {
  const { weeks, weekSubset } = useOrderRange();
  const selectedDiets = useOrderDiets();
  return useGetRangeQuery(
    {
      weeks,
      weekSubset,
      diets: selectedDiets.map((diet) => diet.id),
    },
    { skip: !weeks || !weekSubset || !selectedDiets.length }
  );
};
