import { Modal } from "../../layout/modal";
import { useSelectedDiet } from "../../../store/slices/menu/selectors";
import { FunctionComponent, useEffect, useState } from "react";
import { useAppDispatch } from "../../../store";
import { menuActions } from "../../../store/slices/menu/slice";
import { useLazyGetMenuQuery } from "../../../store/api/menu/endpoints";
import { Loader } from "../../common/loader";
import { useTranslator } from "../../../hooks/useTranslatable";
import { useDietColors } from "../../../hooks/useDietColors";
import { useGetDietsQuery } from "../../../store/api/diets/endpoints";
import styles from "./menuModal.module.css";
import { Button } from "../../common/button";
import { Translation } from "../../dictionary/translation";
import first from "../../../images/numbers/1.svg";
import second from "../../../images/numbers/2.svg";
import third from "../../../images/numbers/3.svg";
import fourth from "../../../images/numbers/4.svg";
import fifth from "../../../images/numbers/5.svg";
import sixth from "../../../images/numbers/6.svg";

const icons = [first, second, third, fourth, fifth, sixth];

export const MenuModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const selectedDiet = useSelectedDiet();
  const { data: diets } = useGetDietsQuery();
  const [selectedWeek, setSelectedWeek] = useState(0);
  // const diet = useDiet
  const translator = useTranslator();
  const [getMenu, { data: menu, isLoading, isFetching }] =
    useLazyGetMenuQuery();
  const style = useDietColors(
    diets?.diets.find((diet) => diet.id === selectedDiet)?.type || undefined
  );

  useEffect(() => {
    if (selectedDiet) {
      getMenu({
        id: selectedDiet,
      });
    }
    if (!selectedDiet) {
      setSelectedWeek(0);
    }
  }, [selectedDiet, getMenu, setSelectedWeek]);

  return (
    <div style={style}>
      <Modal
        onClose={() => dispatch(menuActions.setSelectedDiet(null))}
        active={!!selectedDiet}
        title={menu && !isFetching ? translator(menu.title) : undefined}
        themeTitle
      >
        {menu && !isLoading && !isFetching ? (
          <div>
            <div className={`text`}>
              <p>{translator(menu.description)}</p>
            </div>
            <div className={styles.menuModal__row}>
              {menu.days.map((day, index) => (
                <div className={styles.menuModal__rowItem} key={index}>
                  <Button
                    fullWidth
                    text={index !== selectedWeek}
                    onClick={() => setSelectedWeek(index)}
                  >
                    <Translation k={`menu.week.${index}`} />
                  </Button>
                </div>
              ))}
            </div>
            {menu.days.length
              ? menu.days[selectedWeek].map((day, index) => (
                  <div key={index} className={styles.menuModal__day}>
                    <h3 className={`h3`}>{translator(day.title)}</h3>
                    <div className={styles.menuModal__grid}>
                      {menu.days[selectedWeek][index].meals.map(
                        (item, index) => (
                          <div className={styles.menuModal__meal}>
                            <img
                              className={styles.menuModal__mealNumber}
                              src={icons[index]}
                              alt={""}
                            />
                            <div className={styles.menuModal__mealContent}>
                              <h4 className={`h3 h3_noMargin`}>
                                {translator(item.title)}
                              </h4>
                              <div className={`text`}>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: translator(item.description),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))
              : null}
          </div>
        ) : (
          <Loader />
        )}
      </Modal>
    </div>
  );
};
