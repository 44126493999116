import { HeaderDropdown } from "../headerDropdown";
import { FunctionComponent, useEffect } from "react";
import { useLocale } from "../../../store/slices/common/selectors";
import { Translation } from "../../dictionary/translation";
import { useGetTranslationsQuery } from "../../../store/api/common/endpoints";
import { useAppDispatch } from "../../../store";
import { commonActions } from "../../../store/slices/common/slice";

export const LangDropdown: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const lang = useLocale();
  const { data } = useGetTranslationsQuery();

  useEffect(() => {
    document.documentElement.lang = lang;
  }, [lang]);

  useEffect(() => {
    if (data && !data.languages.find((language) => language.code === lang)) {
      dispatch(commonActions.setLocale(data.languages[0].code));
    }
  }, [lang, dispatch, data]);

  if (!data) return null;

  return (
    <HeaderDropdown
      title={<Translation k={lang} />}
      options={data.languages.map((language) => ({
        label: <Translation k={language.code} />,
        onClick: () => dispatch(commonActions.setLocale(language.code)),
      }))}
    />
  );
};
