import {
  CSSProperties,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
} from "react";
import styles from "./contentCard.module.css";
import { useModifiers } from "../../../hooks/useModifiers";
import { ActionGroup } from "../../layout/actionGroup";

export interface ContentCardProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title?: string | ReactNode;
  narrow?: boolean;
  actions?: ReactNode;
}

export const ContentCard: FunctionComponent<ContentCardProps> = ({
  title,
  narrow,
  children,
  className,
  actions,
  ...rest
}) => {
  const mods = useModifiers(styles, "contentCard", { narrow });

  return (
    <div className={`${styles.contentCard} ${mods} ${className}`} {...rest}>
      {actions || title ? (
        <div className={styles.contentCard__header}>
          <div>
            {title ? (
              <div className={`${styles.contentCard__title}`}>
                <h2 className={`h2 h2_noMargin`}>{title}</h2>
              </div>
            ) : null}
          </div>
          <div className={styles.contentCard__actions}>
            <ActionGroup>{actions}</ActionGroup>
          </div>
        </div>
      ) : null}
      <div className={styles.contentCard__grid}>{children}</div>
    </div>
  );
};

export interface ContentCardItemProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  span?: 1 | 2;
  title?: string | ReactNode;
}

export const ContentCardItem: FunctionComponent<ContentCardItemProps> = ({
  children,
  className,
  style,
  title,
  span,
  ...rest
}) => {
  return (
    <div
      className={`${styles.contentCard__item} ${className}`}
      style={{ ...({ "--span": span } as CSSProperties), ...style }}
      {...rest}
    >
      {title ? <h3 className={`h3 h3_noMargin ${styles.contentCard__itemTitle}`}>{title}</h3> : null}
      {children}
    </div>
  );
};
