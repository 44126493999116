import {
  ContentCard,
  ContentCardItem,
} from "../../../../components/cards/content";
import { Translation } from "../../../../components/dictionary/translation";
import { OptionGroup } from "../../../../components/layout/optionGroup";
import { RadioButton } from "../../../../components/common/radioButton";
import { WeekSubset } from "../../../../types/calendar";
import { DeliveryCalendar } from "../../../../components/common/deliveryCalendar";
import { InfoCard } from "../../../../components/cards/info";
import {
  PriceGrid,
  PriceGridItem,
} from "../../../../components/layout/priceGrid";
import { useAppDispatch } from "../../../../store";
import { useOrderRange } from "../../../../store/slices/order/selectors";
import { ChangeEvent } from "react";
import { orderActions } from "../../../../store/slices/order/slice";
import { useTranslator } from "../../../../hooks/useTranslatable";
import { Loader } from "../../../../components/common/loader";
import { useRange } from "../../../../store/api/range/hooks";

export const RangePicker = () => {
  const dispatch = useAppDispatch();
  const translator = useTranslator();
  const { weeks, weekSubset } = useOrderRange();
  const { data: range, isFetching } = useRange();

  const handleWeeksChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(orderActions.setWeeks(parseInt(event.target.value)));
  };

  const handleWeekSubsetChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(orderActions.setWeekSubset(event.target.value as WeekSubset));
  };
  return (
    <ContentCard title={<Translation k={`order.subscription.title`} />}>
      <ContentCardItem span={1}>
        <OptionGroup legend={<Translation k={`order.subscription.duration`} />}>
          <RadioButton
            name={"duration"}
            value={1}
            checked={weeks === 1}
            onChange={handleWeeksChange}
          >
            <Translation k={`order.subscription.oneWeek`} />
          </RadioButton>
          <RadioButton
            name={"duration"}
            value={2}
            checked={weeks === 2}
            onChange={handleWeeksChange}
          >
            <Translation k={`order.subscription.twoWeeks`} />
          </RadioButton>
          <RadioButton
            name={"duration"}
            value={4}
            checked={weeks === 4}
            onChange={handleWeeksChange}
          >
            <Translation k={`order.subscription.fourWeeks`} />
          </RadioButton>
        </OptionGroup>
      </ContentCardItem>
      <ContentCardItem span={1}>
        <OptionGroup
          legend={<Translation k={`order.subscription.weekSubset`} />}
        >
          <RadioButton
            name={"week-subset"}
            value={WeekSubset.all}
            checked={weekSubset === WeekSubset.all}
            onChange={handleWeekSubsetChange}
          >
            <Translation k={`order.subscription.allDays`} />
          </RadioButton>
          <RadioButton
            name={"week-subset"}
            value={WeekSubset.workdays}
            checked={weekSubset === WeekSubset.workdays}
            onChange={handleWeekSubsetChange}
          >
            <Translation k={`order.subscription.workdays`} />
          </RadioButton>
          <RadioButton
            name={"week-subset"}
            value={WeekSubset.weekend}
            checked={weekSubset === WeekSubset.weekend}
            onChange={handleWeekSubsetChange}
          >
            <Translation k={`order.subscription.weekends`} />
          </RadioButton>
        </OptionGroup>
      </ContentCardItem>
      {range?.orderRange ? (
        <InfoCard warning>
          <Translation k={"order.subscription.existingOrder"} />
        </InfoCard>
      ) : null}
      <DeliveryCalendar range={range} loading={isFetching} />
      {range && !isFetching ? (
        <>
          <InfoCard>{translator(range.info)}</InfoCard>
          <PriceGrid
            title={<Translation k={`order.subscription.mealPriceTitle`} />}
          >
            <PriceGridItem value={range.price.days}>
              <Translation k={`order.subscription.mealDaysLabel`} />
            </PriceGridItem>
            <PriceGridItem price={range.price.value}>
              <Translation k={`order.subscription.mealPriceLabel`} />
            </PriceGridItem>
            <PriceGridItem total price={range.price.total}>
              <Translation k={`order.subscription.mealPriceTotalLabel`} />
            </PriceGridItem>
          </PriceGrid>
        </>
      ) : (
        <Loader />
      )}
    </ContentCard>
  );
};
