import {
  configureStore,
  ThunkAction,
  Action,
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { api } from "./api/api";
import { orderSlice } from "./slices/order/slice";
import { commonSlice } from "./slices/common/slice";
import { loginSlice } from "./slices/login/slice";
import { menuSlice } from "./slices/menu/slice";
import {profileSlice} from "./slices/profile/slice";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (action.error.status === 401) {
        api.dispatch(commonSlice.actions.setToken(null));
      }
    }

    return next(action);
  };

export const index = configureStore({
  reducer: {
    order: orderSlice.reducer,
    common: commonSlice.reducer,
    login: loginSlice.reducer,
    menu: menuSlice.reducer,
    profile: profileSlice.reducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof index.dispatch;
export type RootState = ReturnType<typeof index.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
