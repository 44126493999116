import { api } from "../api";
import { DietType } from "./types";

export const dietsEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getDiets: build.query<{ diets: DietType[] }, void>({
      query: () => ({ url: "diets" }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetDietsQuery } = dietsEndpoints;
