import { FunctionComponent } from "react";
import { ContentCard } from "../../../../components/cards/content";
import { Translation } from "../../../../components/dictionary/translation";
import { AddressForm } from "../addressForm";
import {
  PriceGrid,
  PriceGridItem,
} from "../../../../components/layout/priceGrid";
import { LocationPicker } from "../locationPicker";
import { useOrderDelivery } from "../../../../store/slices/order/selectors";
import { Locations } from "../../../../store/slices/order/types";
import { useRange } from "../../../../store/api/range/hooks";
import { useGetDeliveryQuery } from "../../../../store/api/delivery/endpoints";
import { Loader } from "../../../../components/common/loader";
import {
  useDeliveryTotal,
  useSelectedDelivery,
} from "../../../../hooks/useDeliveryTotal";

export const DeliveryCard: FunctionComponent = () => {
  const { location } = useOrderDelivery();
  const { data: range } = useRange();
  const { data: deliveryInfo } = useGetDeliveryQuery();
  const total = useDeliveryTotal();
  const selectedDelivery = useSelectedDelivery();

  if (!deliveryInfo || !range) {
    return (
      <ContentCard title={<Translation k={`order.delivery.title`} />}>
        <Loader />{" "}
      </ContentCard>
    );
  }

  return (
    <ContentCard title={<Translation k={`order.delivery.title`} />}>
      <LocationPicker />
      <AddressForm />
      {location !== Locations.Other && selectedDelivery && total ? (
        <PriceGrid title={<Translation k={`order.delivery.price`} />}>
          <PriceGridItem price={selectedDelivery.price}>
            <Translation k={`order.delivery.priceLabel`} />
          </PriceGridItem>
          <PriceGridItem value={range.deliveryTimes}>
            <Translation k={`order.delivery.count`} />
          </PriceGridItem>
          <PriceGridItem
            total
            formatterOptions={{ noUnits: true }}
            price={total}
          >
            <Translation k={`order.delivery.total`} />
          </PriceGridItem>
        </PriceGrid>
      ) : null}
    </ContentCard>
  );
};
