import { InputHTMLAttributes, ReactNode, VoidFunctionComponent } from "react";
import styles from "./input.module.css";
import { Label } from "../label";
import { ErrorPropType, Errors } from "../errors";
import { useModifiers } from "../../../hooks/useModifiers";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  error?: ErrorPropType;
}

export const Input: VoidFunctionComponent<InputProps> = ({
  label,
  className,
  error,
  disabled,
  ...rest
}) => {
  const mods = useModifiers(
    styles,
    "input",
    { error: !!error, disabled: disabled },
    { withBaseSelector: true, inherited: className }
  );

  return (
    <div className={mods}>
      <label>
        {label ? <Label>{label}</Label> : null}
        <input className={styles.input__field} disabled={disabled} {...rest} />
        <Errors error={error} />
      </label>
    </div>
  );
};
