import { ContentCard } from "../../components/cards/content";
import { Translation } from "../../components/dictionary/translation";

export const OrderSuccess = () => {
  return (
    <ContentCard title={<Translation k={"order.success.title"} />} narrow>
      <div className={"text"}>
        <p>
          <Translation k={"order.success.message"} />
        </p>
      </div>
    </ContentCard>
  );
};
