import { FunctionComponent, useEffect } from "react";
import { ActionGroup } from "../../../../components/layout/actionGroup";
import { Button } from "../../../../components/common/button";
import { Translation } from "../../../../components/dictionary/translation";
import { useSubmitOrderMutation } from "../../../../store/api/order/endpoints";
import {
  useOrder,
  useOrderErrors,
} from "../../../../store/slices/order/selectors";
import { useAppDispatch } from "../../../../store";
import { orderActions } from "../../../../store/slices/order/slice";
import { OrderErrorType } from "../../../../store/slices/order/types";
import { ContentCard } from "../../../../components/cards/content";
import { useRange } from "../../../../store/api/range/hooks";
import { usePriceFormatter } from "hooks/useFormattedPrice";
import { addPrices } from "../../../../helpers/prices";
import { useDeliveryTotal } from "../../../../hooks/useDeliveryTotal";
import { Navigate } from "react-router-dom";
import { SumTable } from "../../../../components/layout/sumTable";
import { InfoCard } from "../../../../components/cards/info";

export const SubmitOrder: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const order = useOrder();
  const [submit, { error, isLoading, isSuccess, data: submitData }] =
    useSubmitOrderMutation();
  const { data: range } = useRange();
  const priceFormatter = usePriceFormatter();
  const deliveryPriceRaw = useDeliveryTotal();
  const errors = useOrderErrors();

  useEffect(() => {
    if (error && "data" in error) {
      dispatch(orderActions.setError(error?.data as OrderErrorType));
    }
  }, [dispatch, error]);

  if (!range) {
    return null;
  }

  if (isSuccess && submitData) {
    if (submitData.orderId) {
      return <Navigate to={`/order/${submitData.orderId}`} replace />;
    }
    return <Navigate to={"/order/success"} replace />;
  }

  const mealPrice = priceFormatter(range.price.total, { noUnits: true });

  return (
    <ContentCard>
      <SumTable
        items={[
          {
            label: <Translation k={"order.submit.mealSum"} />,
            value: mealPrice,
          },
          {
            label: <Translation k={"order.submit.deliverySum"} />,
            value: deliveryPriceRaw ? (
              priceFormatter(deliveryPriceRaw, { noUnits: true })
            ) : (
              <Translation k={"order.submit.willBeCalculated"} />
            ),
          },
          {
            label: <Translation k={"order.submit.totalSum"} />,
            value: deliveryPriceRaw ? (
              priceFormatter(addPrices(range.price.total, deliveryPriceRaw), {
                noUnits: true,
              })
            ) : (
              <Translation k={"order.submit.willBeCalculated"} />
            ),
          },
        ]}
      />
      <ActionGroup end>
        <Button
          onClick={() => {
            submit(order);
            dispatch(orderActions.setError(null));
          }}
          loading={isLoading}
        >
          <Translation k={"common.submit-order"} />
        </Button>
      </ActionGroup>
      {errors?.message ? <InfoCard danger>{errors?.message}</InfoCard> : null}
    </ContentCard>
  );
};
