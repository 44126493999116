import { ContentCard } from "../../components/cards/content";
import { Translation } from "../../components/dictionary/translation";
import { ActionGroup } from "../../components/layout/actionGroup";
import { Link } from "../../components/common/button";
import { useSearchParams } from "react-router-dom";

export const PaymentFail = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order_id") || null;

  return (
    <ContentCard title={<Translation k={"order.paymentFail.title"} />} narrow>
      <div className={"text"}>
        <p>
          <Translation k={"order.paymentFail"} />
        </p>
      </div>
      <ActionGroup end>
        {orderId ? (
          <Link to={`/order/${orderId}`}>
            <Translation k={"order.paymentFail.backToOrder"} />
          </Link>
        ) : (
          <Link to={`/profile`}>
            <Translation k={"order.paymentSuccess.toProfile"} />
          </Link>
        )}
      </ActionGroup>
    </ContentCard>
  );
};
