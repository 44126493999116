import styles from "./header.module.css";
import { ActionGroup } from "../../layout/actionGroup";
import { Logo } from "../../layout/logo";
import { ProfileButton } from "../profileButton";
import { LangDropdown } from "../langDropdown";

export const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.header__wing}>
        <Logo />
      </div>
      <div className={`${styles.header__wing} ${styles.header__nav}`}>
        <ActionGroup>
          <ProfileButton />
        </ActionGroup>
        <ActionGroup>
          <LangDropdown />
        </ActionGroup>
      </div>
    </header>
  );
};
