import { ChangeEvent, useCallback, FunctionComponent, useEffect } from "react";
import { Translation } from "../../../../components/dictionary/translation";
import { RadioButton } from "../../../../components/common/radioButton";
import { OptionGroup } from "../../../../components/layout/optionGroup";
import { useOrderDelivery } from "../../../../store/slices/order/selectors";
import { Locations } from "../../../../store/slices/order/types";
import { useAppDispatch } from "../../../../store";
import { orderActions } from "../../../../store/slices/order/slice";
import { useGetDeliveryQuery } from "../../../../store/api/delivery/endpoints";
import { Loader } from "../../../../components/common/loader";
import { useTranslator } from "../../../../hooks/useTranslatable";

export const LocationPicker: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { location } = useOrderDelivery();
  const { data: deliveryInfo } = useGetDeliveryQuery();
  const translator = useTranslator();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(orderActions.setLocation(event.target.value as Locations));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!location && deliveryInfo) {
      dispatch(orderActions.setLocation(deliveryInfo.prices[0].location));
    }
  }, [dispatch, location, deliveryInfo]);

  if (!deliveryInfo) {
    return <Loader />;
  }

  return (
    <OptionGroup legend={<Translation k={`order.delivery.location.title`} />}>
      {deliveryInfo.prices.map((price) => (
        <RadioButton
          key={price.location}
          name={"location"}
          value={price.location}
          checked={location === price.location}
          description={translator(price.locationDescription)}
          onChange={handleChange}
        >
          {translator(price.locationTitle)}
        </RadioButton>
      ))}
      <RadioButton
        name={"location"}
        value={Locations.Other}
        checked={location === Locations.Other}
        onChange={handleChange}
      >
        <Translation k={`order.delivery.location.other`} />
      </RadioButton>
    </OptionGroup>
  );
};
