import { FunctionComponent, useEffect, useMemo } from "react";
import { ContentCard } from "../../components/cards/content";
import { Translation } from "../../components/dictionary/translation";
import { FormGrid, FormItem } from "../../components/layout/formGrid";
import { Input } from "../../components/common/input";
import { useAppDispatch } from "../../store";
import { useLoginForm } from "../../store/slices/login/selectors";
import { loginActions } from "../../store/slices/login/slice";
import { Button } from "../../components/common/button";
import { useLoginMutation } from "../../store/api/login/endpoints";
import { ErrorType } from "../../types/errors";
import { commonActions } from "../../store/slices/common/slice";
import { Navigate } from "react-router-dom";
import { ActionGroup } from "../../components/layout/actionGroup";
import { LinkInline } from "../../components/common/link";

export const Login: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const form = useLoginForm();
  const [login, { data, isLoading, error }] = useLoginMutation();

  const errors = useMemo(
    () =>
      (error && "data" in error ? error.data : undefined) as
        | ErrorType<"email" | "password">
        | undefined,
    [error]
  );

  useEffect(() => {
    if (data) {
      dispatch(commonActions.setToken(data.token));
      dispatch(loginActions.resetLoginForm());
    }
  }, [data, dispatch]);

  if (data) {
    return <Navigate to={"/profile"} replace />;
  }

  return (
    <ContentCard title={<Translation k={"login.title"} />} narrow>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          login(form);
        }}
      >
        <FormGrid>
          <FormItem span={2}>
            <Input
              name={"email"}
              type={"email"}
              label={<Translation k={`common.email`} />}
              autoComplete={"email"}
              value={form.email}
              onChange={(event) =>
                dispatch(loginActions.setEmail(event.target.value))
              }
              error={errors?.errors?.["email"]}
            />
          </FormItem>
          <FormItem span={2}>
            <Input
              name={"password"}
              type={"password"}
              label={<Translation k={`common.password`} />}
              autoComplete={"off"}
              value={form.password}
              onChange={(event) =>
                dispatch(loginActions.setPassword(event.target.value))
              }
              error={errors?.errors?.["password"]}
            />
          </FormItem>
          <FormItem span={2}>
            <ActionGroup end>
              <LinkInline to={"/password/forgot"}>
                <Translation k={"common.forgotPassword"} />
              </LinkInline>
            </ActionGroup>
          </FormItem>
          <FormItem span={2} footer>
            <Button type={"submit"} loading={isLoading}>
              <Translation k={"login.button"} />
            </Button>
          </FormItem>
        </FormGrid>
      </form>
    </ContentCard>
  );
};
