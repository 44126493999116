import { api } from "../api";
import {MenuType} from "./types";

export const menuEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    getMenu: build.query<MenuType, { id: string }>({
      query: (query) => {
        return {
          url: `menu`,
          params: query,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useLazyGetMenuQuery } = menuEndpoint;
