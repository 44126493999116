import { FunctionComponent, useEffect } from "react";
import { DeliveryCard } from "./components/deliveryCard";
import { PersonalDataCard } from "./components/personalDataCard";
import { RangePicker } from "./components/rangePicker";
import { DietPicker } from "./components/dietPicker";
import { useGetDietsQuery } from "../../store/api/diets/endpoints";
import { useOrderDiets } from "../../store/slices/order/selectors";
import { useSearchParams } from "react-router-dom";
import { orderActions } from "../../store/slices/order/slice";
import { useAppDispatch } from "../../store";
import { SubmitOrder } from "./components/submitOrder";
import { useRange } from "../../store/api/range/hooks";
import { InfoCard } from "../../components/cards/info";

export const OrderPage: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { data } = useGetDietsQuery();
  const selectedDiets = useOrderDiets();
  const [searchParams] = useSearchParams();
  const selected = searchParams.get("selected") || null;
  const { data: range } = useRange();

  useEffect(() => {
    if (data && selected) {
      if (data.diets.find((diet) => diet.id === selected)) {
        dispatch(orderActions.addDiet({ id: selected, unique: true }));
      }
    }
  }, [data, selected, dispatch]);

  if (range?.errorMessage) {
    return <InfoCard danger>{range?.errorMessage}</InfoCard>;
  }

  return (
    <>
      <DietPicker />
      {data && selectedDiets.length ? (
        <>
          <RangePicker />
          <DeliveryCard />
          <PersonalDataCard />
          <SubmitOrder />
        </>
      ) : null}
    </>
  );
};
