import {
  addDays,
  endOfWeek,
  format,
  getDay,
  isAfter,
  isBefore,
  isSameDay,
  max,
  min,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { lv, ru, enUS } from "date-fns/locale";
import styles from "./deliveryCalendar.module.css";
import {
  CSSProperties,
  Fragment,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  useMemo,
} from "react";
import { useModifiers } from "../../../hooks/useModifiers";
import { Delivery } from "../../icons";
import { RangeType } from "../../../store/api/range/types";
import { Loader } from "../loader";
import { useLocale } from "../../../store/slices/common/selectors";

const locales = {
  lv: lv,
  ru: ru,
  en: enUS,
};

export interface DayProps extends HTMLAttributes<HTMLDivElement> {
  today?: boolean;
  included?: boolean;
  past?: boolean;
  header?: boolean;
  row: number;
  column: number;
  month?: string;
}

const Day: FunctionComponent<DayProps> = ({
  today,
  included,
  past,
  header,
  row,
  column,
  children,
  month,
}) => {
  const mods = useModifiers(styles, "deliveryCalendar__day", {
    today,
    included,
    past,
    header,
  });
  return (
    <div
      className={`${styles.deliveryCalendar__day} ${mods}`}
      style={{
        gridColumn: column,
        gridRow: row,
      }}
    >
      <div className={styles.deliveryCalendar__dayWing}>{month || ""}</div>
      <div className={styles.deliveryCalendar__dayContent}>{children}</div>
      <div className={styles.deliveryCalendar__dayWing} />
    </div>
  );
};

export interface DeliveryCalendarProps {
  range?: RangeType;
  loading?: boolean;
}

export const DeliveryCalendar: FunctionComponent<DeliveryCalendarProps> = ({
  range,
  loading,
}) => {
  const locale = locales[useLocale()];
  console.log("range", range);

  const today = useMemo(() => new Date(), []);

  const staringDate = useMemo(() => {
    const startOfCurrentWeek = startOfWeek(new Date(), { locale });
    if (!range) return startOfCurrentWeek;
    const firstDayInRange = new Date(range.deliveryDates[0]);
    const firstDeliveryWeekStart = startOfWeek(firstDayInRange, { locale });
    if (range.orderRange) {
      const firstDayInOrder = new Date(range.orderRange.deliveryDates[0]);
      const firstDayInOrderWeekStart = startOfWeek(firstDayInOrder, { locale });
      return min([
        startOfCurrentWeek,
        firstDeliveryWeekStart,
        firstDayInOrderWeekStart,
      ]);
    } else {
      return min([startOfCurrentWeek, firstDeliveryWeekStart]);
    }
  }, [locale, range]);

  const endingDate = useMemo<Date>(() => {
    const defaultEndingDate = addDays(staringDate, 7 * 5 - 1);
    if (!range) return defaultEndingDate;
    const lastDayInRange = new Date(range.dates[range.dates.length - 1]);
    const rangeEndingDate = endOfWeek(new Date(lastDayInRange), { locale });
    return max([defaultEndingDate, rangeEndingDate]);
  }, [locale, staringDate, range]);

  const days = useMemo<Date[]>(() => {
    const days = [];
    let currentDate = staringDate;
    let i = 0;
    while (!isAfter(currentDate, endingDate)) {
      days.push(currentDate);
      currentDate = addDays(currentDate, 1);
      i++;
      if (i >= 100) {
        break;
      }
    }
    return days;
  }, [staringDate, endingDate]);

  const getDays = useMemo(() => {
    let renderedDays: ReactNode[] = [];
    let currentMonth = startOfMonth(days[0]);
    let monthShouldRender = true;
    days.forEach((day, index) => {
      if (!isSameDay(startOfMonth(day), currentMonth)) {
        monthShouldRender = true;
        currentMonth = startOfMonth(day);
      }
      const included = range?.dates?.includes(format(day, "yyyy-MM-dd"));
      const past = range?.orderRange
        ? range?.orderRange.dates?.includes(format(day, "yyyy-MM-dd"))
        : included && isBefore(day, today);
      renderedDays.push(
        <Day
          today={isSameDay(day, today)}
          included={included}
          past={past}
          column={(index % 7) + 1}
          row={Math.floor(index / 7) + 2}
          key={index}
          month={
            monthShouldRender
              ? format(currentMonth, "LLL", { locale })
              : undefined
          }
        >
          {format(day, "d")}
        </Day>
      );
      monthShouldRender = false;
    });

    return { days: renderedDays };
  }, [locale, days, today, range]);

  return (
    <div
      className={styles.deliveryCalendar}
      style={{ "--numberOfRows": days.length / 7 + 1 } as CSSProperties}
    >
      {range && range.deliveryDays
        ? range.deliveryDays.map((day) => (
            <Fragment key={day}>
              <div
                className={`${styles.deliveryCalendar__deliveryOutline} ${styles.deliveryCalendar__deliveryOutline_hoverZone}`}
                style={
                  {
                    "--currentColumn": day - 1 >= 0 ? day : day + 7,
                  } as CSSProperties
                }
              />
              <div
                className={`${styles.deliveryCalendar__deliveryOutline}`}
                style={
                  {
                    "--currentColumn": day - 1 >= 0 ? day : day + 7,
                  } as CSSProperties
                }
              />
            </Fragment>
          ))
        : null}
      {new Array(7).fill("").map((item, index) => (
        <Day row={1} column={index + 1} header key={index}>
          <div className={styles.deliveryCalendar__header}>
            {range &&
            range.deliveryDays &&
            range.deliveryDays.includes(getDay(addDays(staringDate, index))) ? (
              <Delivery />
            ) : null}{" "}
            <div>
              {format(addDays(staringDate, index), "EEEEEE", { locale })}
            </div>
          </div>
        </Day>
      ))}
      {getDays.days}
      {loading ? (
        <div className={styles.deliveryCalendar__loadingOverlay}>
          <div className={styles.deliveryCalendar__deliveryOverlayBackground} />
          <Loader />
        </div>
      ) : null}
    </div>
  );
};
