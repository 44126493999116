import { FunctionComponent } from "react";
import { ContentCard } from "../../../../components/cards/content";
import { Translation } from "../../../../components/dictionary/translation";
import { FormGrid, FormItem } from "../../../../components/layout/formGrid";
import { Input } from "../../../../components/common/input";
import { Checkbox } from "../../../../components/common/checkbox";
import { useAppDispatch } from "../../../../store";
import {
  useOrderErrors,
  useOrderPersonalInfo,
} from "../../../../store/slices/order/selectors";
import { orderActions } from "../../../../store/slices/order/slice";
import { useUser } from "../../../../store/api/user/hooks";
import { Loader } from "../../../../components/common/loader";
import { Button } from "../../../../components/common/button";
import { useLogoutMutation } from "../../../../store/api/login/endpoints";

export const PersonalDataCard: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const personalInfo = useOrderPersonalInfo();
  const errors = useOrderErrors();
  const { data: user, isLoading } = useUser();
  const [logout, { isLoading: isLogoutLoading }] = useLogoutMutation();

  if (isLoading) {
    return (
      <ContentCard title={<Translation k={`order.personalData.order-as`} />}>
        <Loader />
      </ContentCard>
    );
  }

  if (user) {
    return (
      <ContentCard
        title={<Translation k={`order.personalData.order-as`} />}
        actions={
          <Button onClick={() => logout()} loading={isLogoutLoading} text>
            <Translation k={"common.logout"} />
          </Button>
        }
      >
        <div className={`text`}>
          <p>
            <b>{user.personalInfo.name}</b> ({user.personalInfo.email})
          </p>
        </div>
      </ContentCard>
    );
  }

  return (
    <ContentCard title={<Translation k={`order.personalData.title`} />}>
      <FormGrid>
        <FormItem span={2}>
          <Input
            name={"name"}
            label={<Translation k={`common.name`} />}
            autoComplete={"name"}
            value={personalInfo.name}
            onChange={(event) =>
              dispatch(orderActions.setName(event.target.value))
            }
            error={errors?.errors?.["personalInfo.name"]}
          />
        </FormItem>
        <FormItem>
          <Input
            name={"email"}
            type={"email"}
            label={<Translation k={`common.email`} />}
            autoComplete={"email"}
            value={personalInfo.email}
            onChange={(event) =>
              dispatch(orderActions.setEmail(event.target.value))
            }
            error={errors?.errors?.["personalInfo.email"]}
          />
        </FormItem>
        <FormItem>
          <Input
            name={"phone"}
            type={"tel"}
            label={<Translation k={`common.phone`} />}
            autoComplete={"tel"}
            value={personalInfo.phone}
            onChange={(event) =>
              dispatch(orderActions.setPhone(event.target.value))
            }
            error={errors?.errors?.["personalInfo.phone"]}
          />
        </FormItem>
        <FormItem>
          <Input
            name={"password"}
            type={"password"}
            label={<Translation k={`common.password`} />}
            autoComplete={"off"}
            value={personalInfo.password}
            onChange={(event) =>
              dispatch(orderActions.setPassword(event.target.value))
            }
            error={errors?.errors?.["personalInfo.password"]}
          />
        </FormItem>
        <FormItem>
          <Input
            name={"passwordConfirmation"}
            type={"password"}
            label={<Translation k={`common.passwordConfirmation`} />}
            autoComplete={"off"}
            value={personalInfo.password_confirmation}
            onChange={(event) =>
              dispatch(orderActions.setPasswordConfirmation(event.target.value))
            }
            error={errors?.errors?.["personalInfo.password_confirmation"]}
          />
        </FormItem>
        <FormItem span={2}>
          <Checkbox
            checked={personalInfo.agree}
            onChange={(event) =>
              dispatch(orderActions.setAgree(event.target.checked))
            }
            error={errors?.errors?.["personalInfo.agree"]}
          >
            <Translation k={`common.terms`} />
          </Checkbox>
        </FormItem>
      </FormGrid>
    </ContentCard>
  );
};
