import { PersonalInfoType } from "./personal";
import { RangeType } from "../store/api/range/types";
import { PriceType } from "./price";
import { PaymentMethods } from "./payment";

export enum OrderStatus {
  PendingAddress = 0,
  PendingPayment = 1,
  CacheAwaiting = 9,
  Success = 10,
  PaymentFailed = -1,
  RejectedAddress = -2,
  Cancelled = -3,
}

export interface IOrderPaymentMethods {
  [PaymentMethods.card]: {
    commission?: PriceType;
    disclaimer?: null | string;
    enabled: boolean;
  };
  [PaymentMethods.bank]: {
    commission?: PriceType;
    disclaimer?: null | string;
    enabled: boolean;
    banks: {
      IsAccountLinkingSupported: 1 | 0;
      code: string;
      imageUrl: string;
      name: string;
    }[];
  };
  [PaymentMethods.cash]: {
    commission?: PriceType;
    disclaimer?: null | string;
    enabled: boolean;
  };
  default: PaymentMethods;
}

export type OrderType = {
  id: string;
  address: string;
  comment: string | null;
  personalInfo: PersonalInfoType;
  status: OrderStatus;
  diets: { id: string; comment: string | null }[];
  range: RangeType;
  paymentMethods: IOrderPaymentMethods;
  sum: {
    mealSum: PriceType;
    deliverySum: PriceType;
    total: PriceType;
  };
};
