import { FunctionComponent, HTMLAttributes } from "react";
import styles from "./tag.module.css";
import { useModifiers } from "../../../hooks/useModifiers";

export interface TagProps extends HTMLAttributes<HTMLDivElement> {
  warning?: boolean;
  danger?: boolean;
  success?: boolean;
}

export const Tag: FunctionComponent<TagProps> = ({
  children,
  warning,
  danger,
  success,
  className: inheritedClassName,
  ...props
}) => {
  const className = useModifiers(
    styles,
    "tag",
    {
      warning,
      danger,
      success,
    },
    { withBaseSelector: true, inherited: inheritedClassName }
  );

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};
