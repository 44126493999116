import { FunctionComponent } from "react";
import { useGetTranslationsQuery } from "../../../store/api/common/endpoints";
import { useLocale } from "../../../store/slices/common/selectors";

export const useText = (k: string): string => {
  const { data } = useGetTranslationsQuery();
  const translation = data?.dictionary?.[k];
  const locale = useLocale();
  if (!translation || !locale) return k;
  return translation[locale] || k;
};

export const Translation: FunctionComponent<{ k: string }> = ({ k }) => {
  const text = useText(k);
  return <>{text}</>;
};
