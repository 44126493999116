import { ContentCard } from "../../components/cards/content";
import { Translation } from "../../components/dictionary/translation";
import { ActionGroup } from "../../components/layout/actionGroup";
import { Link } from "../../components/common/button";
import { useSearchParams } from "react-router-dom";
import { PaymentMethods } from "../../types/payment";

export const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const method = searchParams.get("selected") || null;

  return (
    <ContentCard title={<Translation k={"order.paymentSuccess.title"} />} narrow>
      {method === PaymentMethods.cash ? (
        <div className={"text"}>
          <p>
            <Translation k={"order.paymentSuccess.cache"} />
          </p>
        </div>
      ) : (
        <div className={"text"}>
          <p>
            <Translation k={"order.paymentSuccess.message"} />
          </p>
        </div>
      )}
      <ActionGroup end>
        <Link to={`/profile`}>
          <Translation k={"order.paymentSuccess.toProfile"} />
        </Link>
      </ActionGroup>
    </ContentCard>
  );
};
