import { FunctionComponent, ReactNode, useMemo } from "react";
import { OrderStatus, OrderType } from "../../../types/order";
import { DietSummary } from "../dietSummary";
import { ContentCard, ContentCardItem } from "../content";
import { Translation } from "../../dictionary/translation";
import { DeliveryCalendar } from "../../common/deliveryCalendar";
import { InfoCard } from "../info";
import { DeliveryInfoCard } from "../deliveryInfoCard";
import { useTranslator } from "../../../hooks/useTranslatable";
import styles from "./orderInfoCard.module.css";
import { ActionGroup } from "../../layout/actionGroup";
import { Link } from "../../common/button";
import { Tag } from "../../common/tag";
import { usePriceFormatter } from "../../../hooks/useFormattedPrice";

export const OrderInfoCard: FunctionComponent<{
  order: OrderType;
  withControls?: boolean;
}> = ({ order, withControls }) => {
  const priceFormatter = usePriceFormatter();
  const translator = useTranslator();

  const tag: ReactNode = useMemo(() => {
    if (order) {
      switch (order.status) {
        case OrderStatus.PendingAddress: {
          return (
            <Tag warning>
              <Translation k={`order.status.${OrderStatus.PendingAddress}`} />
            </Tag>
          );
        }
        case OrderStatus.PaymentFailed:
        case OrderStatus.PendingPayment: {
          return (
            <Tag warning>
              <Translation k={`order.status.${OrderStatus.PendingPayment}`} />
            </Tag>
          );
        }
        case OrderStatus.Success: {
          return (
            <Tag success>
              <Translation k={`order.status.${OrderStatus.Success}`} />
            </Tag>
          );
        }
        case OrderStatus.CacheAwaiting: {
          return (
            <Tag warning>
              <Translation k={`order.status.${OrderStatus.CacheAwaiting}`} />
              {":"}
              <b>&nbsp;{priceFormatter(order.sum.total, { noUnits: true })}</b>
            </Tag>
          );
        }
        case OrderStatus.Cancelled: {
          return (
            <InfoCard danger>
              <Translation k={`order.status.${OrderStatus.Cancelled}`} />
            </InfoCard>
          );
        }
        case OrderStatus.RejectedAddress: {
          return (
            <InfoCard danger>
              <Translation k={`order.status.${OrderStatus.RejectedAddress}`} />
            </InfoCard>
          );
        }
      }
    }
    return null;
  }, [priceFormatter, order]);

  return (
    <ContentCard>
      {withControls ? (
        <div className={styles.orderInfoCard__controls}>
          <div className={`h2 h2_noMargin ${styles.orderInfoCard__title}`}>
            #{order.id}
          </div>
          <ActionGroup>
            {tag}
            {order.status === OrderStatus.PendingPayment ? (
              <Link to={`/order/${order.id}`}>
                <Translation k={"common.pay"} />
              </Link>
            ) : (
              <Link to={`/order/${order.id}`} text>
                <Translation k={"common.more"} />
              </Link>
            )}
          </ActionGroup>
        </div>
      ) : null}
      {order.diets.map((diet, index) => (
        <DietSummary key={index} id={diet.id} comment={diet.comment} />
      ))}
      <ContentCardItem title={<Translation k={"order.delivery"} />} span={1}>
        <DeliveryCalendar range={order.range} />
        <InfoCard>{translator(order.range.info)}</InfoCard>
      </ContentCardItem>
      <ContentCardItem title={<Translation k={"order.deliveryTo"} />} span={1}>
        <DeliveryInfoCard
          personalInfo={order.personalInfo}
          address={order.address}
          comment={order.comment}
        />
      </ContentCardItem>
    </ContentCard>
  );
};
