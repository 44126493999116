import { api } from "../api";
import { TranslatableType } from "../../../types/translatable";

export const rangeEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    getTerms: build.query<
      {
        text: TranslatableType;
      },
      void
    >({
      query: () => {
        return {
          url: `block/terms-and-conditions`,
        };
      },
    }),
    getPrivacy: build.query<
      {
        text: TranslatableType;
      },
      void
    >({
      query: () => {
        return {
          url: `block/privacy`,
        };
      },
    }),
    getCredentials: build.query<
      {
        text: TranslatableType;
      },
      void
    >({
      query: () => {
        return {
          url: `block/credentials`,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetTermsQuery,
  useGetPrivacyQuery,
  useGetCredentialsQuery,
} = rangeEndpoint;
