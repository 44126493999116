import { FunctionComponent, HTMLAttributes, ReactNode, useEffect } from "react";
import styles from "./modal.module.css";
import { useModifiers } from "hooks/useModifiers";
import { Button } from "../../common/button";
import { Close } from "../../icons";

export interface ModalProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title?: string | ReactNode;
  onClose: () => void;
  active: boolean;
  noScroll?: boolean;
  themeTitle?: boolean;
}

export const Modal: FunctionComponent<ModalProps> = ({
  title,
  onClose = () => {},
  children,
  active,
  noScroll,
  themeTitle,
}) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    if (active) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onClose, active]);

  const mods = useModifiers(styles, "modal", { noScroll,themeTitle });

  return (
    <div
      className={`${styles.modal__wrapper} ${
        active ? styles.modal__wrapper_active : ""
      }`}
    >
      <div className={styles.modal__curtain} onClick={onClose} />
      <div className={styles.modal__container}>
        <div className={`${styles.modal} ${mods}`}>
          <Button
            text
            icon={<Close />}
            className={styles.modal__close}
            onClick={onClose}
          />
          <div className={styles.modal__content}>
            <h2 className={`h2 ${styles.modal__title}`}>{title}</h2>
            <div className={styles.modal__body}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
