import { api } from "../api";
import { commonSlice } from "../../slices/common/slice";

export const loginEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<
      { token: string },
      { email: string; password: string }
    >({
      query: (form) => {
        return {
          url: `login`,
          method: "POST",
          body: form,
        };
      },
    }),
    logout: build.mutation<{}, void>({
      query: () => {
        return {
          url: `logout`,
          method: "POST",
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          //@ts-ignore
          console.log(response?.meta?.response?.ok);
          //@ts-ignore
          if (response?.meta?.response?.ok) {
            dispatch(commonSlice.actions.setToken(null));
          }
        } catch (error) {
          console.log(error);
        }
      },
    }),
    forgotPassword: build.mutation<{message: string}, { email: string }>({
      query: (form) => {
        return {
          url: `password/email`,
          method: "POST",
          body: form,
        };
      },
    }),
    resetPassword: build.mutation<{message: string}, { token: string; password: string, password_confirmation: string, email: string }>({
      query: (form) => {
        return {
          url: `password/reset`,
          method: "POST",
          body: form,
        };
      }
    })
  }),
  overrideExisting: true,
});

export const { useLoginMutation, useLogoutMutation, useForgotPasswordMutation, useResetPasswordMutation } = loginEndpoint;
