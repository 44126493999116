import { FunctionComponent } from "react";
import { ContentCard } from "../../components/cards/content";
import { Translation } from "../../components/dictionary/translation";
import { Link } from "../../components/common/button";
import { ActionGroup } from "../../components/layout/actionGroup";

export const Error: FunctionComponent = () => {
  return (
    <ContentCard title={<Translation k={"common.notFound"} />} narrow>
      <ActionGroup end>
        <Link to={"/"}>
          <Translation k={"common.backToHome"} />
        </Link>
      </ActionGroup>
    </ContentCard>
  );
};
