import { useGetDietsQuery } from "../../../../store/api/diets/endpoints";
import { DietPickerCard } from "../../../../components/cards/dietPicker";
import { useOrderDiets } from "../../../../store/slices/order/selectors";
import { Loader } from "../../../../components/common/loader";
import { Button } from "../../../../components/common/button";
import { useAppDispatch } from "../../../../store";
import { orderActions } from "../../../../store/slices/order/slice";
import styles from "./dietPicker.module.css";
import { Plus } from "../../../../components/icons";
import { Modal } from "../../../../components/layout/modal";
import { useState } from "react";
import { useTranslator } from "../../../../hooks/useTranslatable";
import { DietPickerButton } from "./dietPickerButton";
import { DietPickerButtonGrid } from "./dietPickerButtonGrid";
import { Translation } from "../../../../components/dictionary/translation";

export const DietPicker = () => {
  const dispatch = useAppDispatch();
  const translator = useTranslator();
  const { data } = useGetDietsQuery();
  const selectedDiets = useOrderDiets();
  const [showModal, setShowModal] = useState<false | "add" | "edit">(false);

  if (!data) {
    return <Loader />;
  }

  const diets = data.diets;

  return (
    <>
      <div className={styles.dietPicker}>
        {selectedDiets.map((selectedDiet, index) => {
          const diet = diets.find((diet) => diet.id === selectedDiet.id);
          if (!diet) {
            return null;
          }
          return (
            <DietPickerCard
              key={selectedDiet.id + index}
              diet={diet}
              removable={selectedDiets.length > 1}
              index={index}
              comment={selectedDiet.comment}
              onEdit={() => {
                setShowModal("edit");
              }}
            />
          );
        })}
        <div className={styles.dietPicker__footer}>
          <Button text onClick={() => setShowModal("add")} icon={<Plus />}>
            Add diet
          </Button>
        </div>
      </div>
      <Modal
        title={<Translation k={"order.dietPicker.selectDiet"} />}
        active={!!showModal}
        onClose={() => setShowModal(false)}
      >
        <DietPickerButtonGrid>
          {diets.map((diet) => (
            <DietPickerButton
              diet={diet}
              key={diet.id}
              onClick={() => {
                if (showModal === "add") {
                  dispatch(orderActions.addDiet(diet.id));
                } else if (showModal === "edit") {
                  dispatch(
                    orderActions.changeDiet({ index: 0, newId: diet.id })
                  );
                }
                setShowModal(false);
              }}
            >
              {translator(diet.title)}
            </DietPickerButton>
          ))}
        </DietPickerButtonGrid>
      </Modal>
    </>
  );
};
