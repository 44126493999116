import { useLocale } from "../store/slices/common/selectors";
import { TranslatableType } from "../types/translatable";
import { useCallback } from "react";

export const useTranslatable = (translatable: TranslatableType) => {
  const locale = useLocale();
  return translatable[locale];
};

export const useTranslator = () => {
  const locale = useLocale();
  return useCallback(
    (translatable: TranslatableType) => {
      return translatable[locale];
    },
    [locale]
  );
};
