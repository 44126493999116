import { FunctionComponent, ReactNode } from "react";
import styles from "./sumTable.module.css";

export const SumTable: FunctionComponent<{
  items: { label: string | ReactNode; value: string | ReactNode }[];
}> = ({ items}) => {
  return (
    <div>
      <dl className={styles.sumTable}>
        {items.map(({ label, value }, index) => (
          <div className={styles.sumTable__listItem} key={index}>
            <dt>{label}</dt>
            <div className={styles.sumTable__listSeparator} />
            <dd>{value}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};
