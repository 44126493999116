import { useRange } from "../store/api/range/hooks";
import { useGetDeliveryQuery } from "../store/api/delivery/endpoints";
import { PriceType } from "../types/price";
import { useOrderDelivery } from "../store/slices/order/selectors";
import { multiplyPrice } from "../helpers/prices";

export const useSelectedDelivery = () => {
  const { location } = useOrderDelivery();
  const { data: deliveryInfo } = useGetDeliveryQuery();
  if (!deliveryInfo) {
    return null;
  }
  return deliveryInfo.prices.find((delivery) => delivery.location === location);
};

export const useDeliveryTotal = (): PriceType | null => {
  const { data: range } = useRange();
  const { data: deliveryInfo } = useGetDeliveryQuery();
  const selectedDelivery = useSelectedDelivery();
  if (!range || !deliveryInfo || !selectedDelivery) {
    return null;
  }
  return multiplyPrice(selectedDelivery.price, range.deliveryTimes);
};
