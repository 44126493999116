import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  editedPersonalInfo: {
    name: string | null;
    phone: string | null;
  };
  editingPersonalInfo: boolean;
} = {
  editedPersonalInfo: {
    name: null,
    phone: null,
  },
  editingPersonalInfo: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setEditedName(state, action: PayloadAction<string | null>) {
      state.editedPersonalInfo.name = action.payload;
    },
    setEditedPhone(state, action: PayloadAction<string | null>) {
      state.editedPersonalInfo.phone = action.payload;
    },
    setEditingPersonalInfo(state, action: PayloadAction<boolean>) {
      if(!action.payload) {
        state.editedPersonalInfo.name = null;
        state.editedPersonalInfo.phone = null;
      }
      state.editingPersonalInfo = action.payload;
    }
  },
});

export const profileActions = profileSlice.actions;
