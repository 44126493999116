import { FunctionComponent } from "react";
import styles from "./footer.module.css";
import { LinkInline } from "../../common/link";
import { Translation } from "../../dictionary/translation";
import { useGetCredentialsQuery } from "../../../store/api/texts/endpoints";
import { useTranslator } from "../../../hooks/useTranslatable";
import mastercard from "images/mastercard.svg";
import visa from "images/visa.svg";
import maestro from "images/ms_vrt_pos.svg";

export const Footer: FunctionComponent = () => {
  const { data } = useGetCredentialsQuery();

  const translator = useTranslator();
  console.log(data);

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__wing}>
        <div className={styles.footer__nav}>
          <LinkInline to={"/terms"}>
            <Translation k={"footer.terms"} />
          </LinkInline>
          <LinkInline to={"/privacy"}>
            <Translation k={"footer.privacy"} />
          </LinkInline>
        </div>
      </div>
      {data ? (
        <div className={styles.footer__wing}>
          <div className={styles.footer__credentials}>
            <div
              className={`text ${styles.footer__credentialsText}`}
              dangerouslySetInnerHTML={{ __html: translator(data.text) }}
            ></div>

            <ul className={styles.footer__logos}>
              <li>
                <img src={mastercard} alt={"Mastercard"} />
              </li>
              <li>
                <img src={maestro} alt={"Maestro"} />
              </li>
              <li>
                <img src={visa} alt={"Visa"} />
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </footer>
  );
};
