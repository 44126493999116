import { FunctionComponent } from "react";
import { useGetDietsQuery } from "../../../store/api/diets/endpoints";
import styles from "./diesSummary.module.css";
import { Translation } from "../../dictionary/translation";
import { useTranslator } from "../../../hooks/useTranslatable";
import { useDietColors } from "../../../hooks/useDietColors";
import { ActionGroup } from "../../layout/actionGroup";
import { Button } from "../../common/button";
import { Utensils } from "../../icons";
import { useAppDispatch } from "../../../store";
import { menuActions } from "../../../store/slices/menu/slice";

export const DietSummary: FunctionComponent<{
  id: string;
  comment?: string | null;
}> = ({ id, comment }) => {
  const dispatch = useAppDispatch();
  const { data: diets } = useGetDietsQuery();
  const translator = useTranslator();
  const diet = diets?.diets.find((d) => d.id === id);
  const dietStyles = useDietColors(diet?.type);

  if (!diets) {
    return null;
  }

  if (!diet) {
    return (
      <div className={styles.dietSummary}>
        <div className={`text`}>
          <Translation k={"diet.unknown"} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.dietSummary} style={dietStyles}>
      <div className={styles.dietSummary__row}>
        <div className={`h3 h3_noMargin ${styles.dietSummary__title}`}>
          {translator(diet.title)}
        </div>
        <div className={styles.dietSummary__actions}>
          <ActionGroup>
            <Button
              text
              icon={<Utensils />}
              onClick={() => dispatch(menuActions.setSelectedDiet(id))}
            >
              <Translation k={"common.menu"} />
            </Button>
          </ActionGroup>
        </div>
      </div>
      {comment ? (
        <div className={`${styles.dietSummary__comment} text`}>
          <p>{comment}</p>
        </div>
      ) : null}
    </div>
  );
};
