import { Title } from "../../components/layout/title";
import { Translation } from "../../components/dictionary/translation";
import { PageSection } from "../../components/layout/pageSection";
import { Loader } from "../../components/common/loader";
import { useEffect } from "react";
import { useAppDispatch } from "../../store";
import { orderActions } from "../../store/slices/order/slice";
import { useUser } from "../../store/api/user/hooks";
import { useToken } from "../../store/slices/common/selectors";
import { Navigate } from "react-router-dom";
import { Button } from "../../components/common/button";
import { ProfileGrid } from "./components/profileGrid";
import { ContentCard } from "../../components/cards/content";
import { Close, Edit } from "../../components/icons";
import { useLogoutMutation } from "../../store/api/login/endpoints";
import { PersonalDataForm } from "./components/personalDataForm";
import { OrderInfoCard } from "../../components/cards/orderInfoCard";
import { useEditingPersonalInfo } from "../../store/slices/profile/selectors";
import { profileActions } from "../../store/slices/profile/slice";
import { InfoCard } from "../../components/cards/info";

export const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const token = useToken();
  const { data: user } = useUser();
  const editing = useEditingPersonalInfo();
  const [logout] = useLogoutMutation();

  useEffect(() => {
    dispatch(orderActions.resetDiets());
  }, [dispatch]);

  if (!token) {
    return <Navigate to={"/"} replace />;
  }
  if (!user) {
    return <Loader />;
  }

  return (
    <div>
      <PageSection
        title={
          <Title noMargin>
            <Translation k={"profile.title"} />
          </Title>
        }
        actions={
          <Button text onClick={() => logout()}>
            <Translation k={"common.logout"} />
          </Button>
        }
      >
        <ProfileGrid>
          <ContentCard
            title={<Translation k={"profile.personalInfoTitle"} />}
            actions={
              <Button
                text
                icon={editing ? <Close /> : <Edit />}
                onClick={() =>
                  dispatch(profileActions.setEditingPersonalInfo(!editing))
                }
              >
                <Translation k={editing ? "common.cancel" : "common.edit"} />
              </Button>
            }
          >
            <PersonalDataForm />
          </ContentCard>
        </ProfileGrid>
      </PageSection>
      <PageSection
        title={
          <Title noMargin>
            <Translation k={"profile.orders"} />
          </Title>
        }
      >
        {user.orders.map((order) => (
          <OrderInfoCard key={order.id} order={order} withControls />
        ))}
        {user.orders.length === 0 ? (
          <InfoCard warning>
            <Translation k={"profile.noOrders"} />
          </InfoCard>
        ) : null}
      </PageSection>
    </div>
  );
};
