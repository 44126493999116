import { Title } from "../../components/layout/title";
import { Translation } from "../../components/dictionary/translation";
import { PageSection } from "../../components/layout/pageSection";
import { CardGrid } from "../../components/layout/cardGrid";
import { DietCard } from "../../components/cards/diet";
import { useGetDietsQuery } from "../../store/api/diets/endpoints";
import { Loader } from "../../components/common/loader";
import {useEffect} from "react";
import {useAppDispatch} from "../../store";
import {orderActions} from "../../store/slices/order/slice";

export const HomePage = () => {
  const dispatch = useAppDispatch();
  const { data: dietsData } = useGetDietsQuery();

  useEffect(() => {
    dispatch(orderActions.resetDiets());
  }, [dispatch]);

  if (!dietsData) {
    return <Loader />;
  }
  return (
    <div>
      <PageSection
        title={
          <Title center>
            <Translation k={"home.select-your-diet"} />
          </Title>
        }
      >
        <CardGrid>
          {dietsData.diets.map((diet) => (
            <DietCard key={diet.id} diet={diet} />
          ))}
        </CardGrid>
      </PageSection>
    </div>
  );
};
