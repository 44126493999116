import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { index } from "./store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "focus-visible";
import "./style/theme.css";
import "./style/common.css";
import "./style/typography.css";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Provider store={index}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
