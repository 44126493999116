import { FunctionComponent, HTMLAttributes } from "react";
import styles from "./errors.module.css";

export type ErrorPropType = string | string[] | null | undefined;

export interface ErrorComponentProps extends HTMLAttributes<HTMLDivElement> {
  error: ErrorPropType;
}

export const Errors: FunctionComponent<ErrorComponentProps> = ({ error }) => {
  if (!error) return null;
  return (
    <span className={styles.errors}>
      {error instanceof Array ? (
        error.map((err, i) => (
          <span className={styles.errors__error} key={i}>
            {err}
          </span>
        ))
      ) : (
        <span className={styles.errors__error}>{error}</span>
      )}
    </span>
  );
};
