import { FunctionComponent, useEffect, useState } from "react";
import { useLazyConfirmEmailQuery } from "../../../store/api/emailConfirmation/endpoints";
import { useAppDispatch } from "../../../store";
import { commonActions } from "../../../store/slices/common/slice";
import { Link } from "../../../components/common/button";
import { useParams, useSearchParams } from "react-router-dom";
import { ActionGroup } from "../../../components/layout/actionGroup";
import { Translation } from "../../../components/dictionary/translation";
import { Loader } from "../../../components/common/loader";

export const ConfirmEmailContent: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [triggered, setTriggered] = useState(false);
  const [broken, setBroken] = useState(false);
  const [trigger, { data, isUninitialized, isLoading }] =
    useLazyConfirmEmailQuery();

  useEffect(() => {
    if (id && isUninitialized && !triggered) {
      const expires = searchParams.get("expires");
      const signature = searchParams.get("signature");
      if (!expires || !signature) {
        setBroken(true);
      } else {
        setTriggered(true);
        trigger({ id, expires, signature });
      }
    }
  }, [searchParams, setBroken, trigger, triggered, id, isUninitialized]);

  useEffect(() => {
    if (data) {
      dispatch(commonActions.setToken(data.token));
    }
  }, [dispatch, data]);

  if (broken) {
    return (
      <div className={`test`}>
        <p>
          <Translation k={"email-confirmation.broken-link"} />
        </p>
      </div>
    );
  }

  if (isUninitialized || isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div>
        <Translation k={"email-confirmation.success"} />
      </div>
      <div>
        <ActionGroup end>
          <Link to={data?.orderId ? `/order/${data.orderId}` : `/`} replace>
            {data?.orderId ? (
              <Translation k={"email-confirmation.to-order"} />
            ) : (
              <Translation k={"email-confirmation.to-profile"} />
            )}
          </Link>
        </ActionGroup>
      </div>
    </>
  );
};
