import { CSSProperties, FunctionComponent, HTMLAttributes } from "react";
import styles from "./formGrid.module.css";
import { useModifiers } from "../../../hooks/useModifiers";

export interface FormLayoutProps extends HTMLAttributes<HTMLDivElement> {}

export const FormGrid: FunctionComponent<FormLayoutProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={`${styles.formGrid} ${className}`} {...rest}>
      {children}
    </div>
  );
};

export interface FormLayoutItemProps extends HTMLAttributes<HTMLDivElement> {
  span?: 1 | 2;
  footer?: boolean;
}

export const FormItem: FunctionComponent<FormLayoutItemProps> = ({
  children,
  className,
  style,
  footer,
  span,
  ...rest
}) => {
  const mods = useModifiers(styles, "formGrid__item", { footer: footer });

  return (
    <div
      className={`${styles.formGrid__item} ${className} ${mods}`}
      style={{ ...({ "--span": span } as CSSProperties), ...style }}
      {...rest}
    >
      {children}
    </div>
  );
};
