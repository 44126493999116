import { ContentCard } from "../content";
import { FunctionComponent } from "react";
import { DietType } from "../../../store/api/diets/types";
import { Translatable } from "../../common/translatable";
import styles from "./dietPickerCard.module.css";
import { useDietColors } from "../../../hooks/useDietColors";
import { useFormattedPrice } from "../../../hooks/useFormattedPrice";
import { Translation } from "../../dictionary/translation";
import { ActionGroup } from "../../layout/actionGroup";
import { Button } from "../../common/button";
import { Bin, Utensils } from "../../icons";
import { TextArea } from "../../common/textarea";
import { useAppDispatch } from "../../../store";
import { orderActions } from "../../../store/slices/order/slice";
import { menuActions } from "../../../store/slices/menu/slice";

export const DietPickerCard: FunctionComponent<{
  diet: DietType;
  removable: boolean;
  index: number;
  comment: string | null;
  onEdit: () => void;
}> = ({ diet, removable, index, comment, onEdit }) => {
  const dispatch = useAppDispatch();
  const dietColors = useDietColors(diet.type);
  const price = useFormattedPrice(diet.price);

  return (
    <ContentCard style={dietColors}>
      <div className={styles.dietPickerCard__header}>
        <div className={styles.dietPickerCard__titleWrapper}>
          <h2 className={`${styles.dietPickerCard__title} h1 h1_noMargin`}>
            <Translatable value={diet.title} />
          </h2>
          <div className={styles.dietPickerCard__priceText}>
            <Translation k={"common.price-starting-from"} /> {price}
          </div>
        </div>
        <ActionGroup>
          <Button text onClick={onEdit}>
            <Translation k={"common.change-diet"} />
          </Button>
          <Button
            wireframe
            icon={<Utensils />}
            onClick={() => dispatch(menuActions.setSelectedDiet(diet.id))}
          >
            <Translation k={"common.see-menu"} />
          </Button>
        </ActionGroup>
      </div>
      <div className={styles.dietPickerCard__footer}>
        <div className={styles.dietPickerCard__footerComment}>
          <TextArea
            value={comment || ""}
            onChange={(event) =>
              dispatch(
                orderActions.setDietComment({
                  index: index,
                  comment: event.target.value,
                })
              )
            }
            label={<Translation k={"common.diet.comments"} />}
          />
        </div>
        {removable ? (
          <Button
            text
            icon={<Bin />}
            onClick={() => dispatch(orderActions.removeDiet(index))}
          >
            <Translation k={"common.remove"} />
          </Button>
        ) : null}
      </div>
    </ContentCard>
  );
};
