import styles from "./profileButton.module.css";
import { Link } from "../../common/button";
import { Translation } from "../../dictionary/translation";
import { Profile } from "../../icons";
import { useUser } from "../../../store/api/user/hooks";

export const ProfileButton = () => {
  const { data: user } = useUser();

  return (
    <div className={styles.profileButton}>
      {!user ? (
        <Link to={"/login"} text>
          <Translation k={"common.login"} />
        </Link>
      ) : (
        <Link to={"/profile"} text icon={<Profile />}>
          {user.personalInfo.name}
        </Link>
        // <HeaderDropdown
        //   title={userData.personalInfo.name}
        //   icon={<Profile />}
        //   options={[
        //     {
        //       label: <Translation k={"common.profile"} />,
        //       to: "/",
        //     },
        //     {
        //       label: <Translation k={"common.logout"} />,
        //       onClick: () => {
        //         console.log("logout");
        //       },
        //     },
        //   ]}
        // />
      )}
    </div>
  );
};
