import styles from "./dietPickerButton.module.css";
import { FunctionComponent, HTMLAttributes } from "react";
import { DietType } from "../../../../../store/api/diets/types";
import { useFormattedPrice } from "../../../../../hooks/useFormattedPrice";
import { useTranslatable } from "../../../../../hooks/useTranslatable";
import { useDietColors } from "../../../../../hooks/useDietColors";
import { Translation } from "../../../../../components/dictionary/translation";

export interface DietPickerButtonType
  extends HTMLAttributes<HTMLButtonElement> {
  diet: DietType;
}

export const DietPickerButton: FunctionComponent<DietPickerButtonType> = ({
  diet,
  ...rest
}) => {
  const title = useTranslatable(diet.title);
  const price = useFormattedPrice(diet.price);
  const dietColors = useDietColors(diet.type);

  return (
    <button {...rest} className={styles.dietPickerCard} style={dietColors}>
      <span className={styles.dietPickerCard__image}>
        <img src={diet.image} alt="" />
      </span>
      <span className={styles.dietPickerCard__body}>
        <span className={styles.dietPickerCard__title}>{title}</span>
        <span className={styles.dietPickerCard__price}>
          <Translation k={"common.price-starting-from"} /> {price}
        </span>
      </span>
    </button>
  );
};
