import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import { PriceType } from "../../../types/price";
import {PriceFormatterOptions, useFormattedPrice} from "../../../hooks/useFormattedPrice";
import styles from "./priceGrid.module.css";
import { useModifiers } from "../../../hooks/useModifiers";

export interface PriceGridProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title?: ReactNode;
}

export const PriceGrid: FunctionComponent<PriceGridProps> = ({
  className,
  children,
  title,
  ...rest
}) => {
  return (
    <div className={className} {...rest}>
      {title ? <h2 className={`h3`}>{title}</h2> : null}
      <dl className={`${styles.priceGrid} ${className}`}>{children}</dl>
    </div>
  );
};

export interface PriceGridItemProps extends HTMLAttributes<HTMLDivElement> {
  label?: ReactNode;
  price?: PriceType;
  value?: ReactNode;
  total?: boolean;
  formatterOptions?: PriceFormatterOptions;
}

export const PriceGridItem: FunctionComponent<PriceGridItemProps> = ({
  children,
  className,
  value,
  label,
  total,
  price,
  formatterOptions,
  ...rest
}) => {
  const formattedPrice = useFormattedPrice(price, formatterOptions);
  const classNames = useModifiers(
    styles,
    "priceGrid__item",
    { total },
    { withBaseSelector: true, inherited: className }
  );
  return (
    <div className={classNames}>
      <dt>{children || label}</dt>
      <dd>{formattedPrice || value}</dd>
    </div>
  );
};
