import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import { ContentCard } from "../../components/cards/content";

export const ConfirmEmail: FunctionComponent = () => {
  return (
    <ContentCard title={"Email Confirmation"} narrow>
      <Outlet />
    </ContentCard>
  );
};
