import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  form: {
    email: string;
    password: string;
  };
  resetPasswordForm: {
    password: string;
    password_confirmation: string;
    email: string;
  };
} = {
  form: {
    email: "",
    password: "",
  },
  resetPasswordForm: {
    password: "",
    password_confirmation: "",
    email: "",
  },
};

export const loginSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.form.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.form.password = action.payload;
    },
    resetLoginForm: (state) => {
      state.form = initialState.form;
    },
    setResetPasswordEmail: (state, action: PayloadAction<string>) => {
      state.resetPasswordForm.email = action.payload;
    },
    setResetPasswordPassword: (state, action: PayloadAction<string>) => {
      state.resetPasswordForm.password = action.payload;
    },
    setResetPasswordPasswordConfirmation: (
      state,
      action: PayloadAction<string>
    ) => {
      state.resetPasswordForm.password_confirmation = action.payload;
    },
    resetResetPasswordForm: (state) => {
      state.resetPasswordForm = initialState.resetPasswordForm;
    },
  },
});

export const loginActions = loginSlice.actions;
