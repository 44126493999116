import { FunctionComponent } from "react";
import { Navigate, useLocation } from "react-router-dom";

export const PaymentResultPage: FunctionComponent = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const statusGroup = searchParams.get("statusGroup");
  const orderId = searchParams.get("order_id");

  if (statusGroup !== "failed") {
    return (
      <Navigate
        to={`/order/payment-fail${orderId ? `order_id=${orderId}` : ""}`}
      />
    );
  } else {
    return <Navigate to="/order/payment-success" />;
  }
};
