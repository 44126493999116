import { useAppSelector } from "../../index";

export const useOrder = () => {
  return useAppSelector((state) => state.order.order);
};

export const useOrderDelivery = () => {
  return useAppSelector((state) => state.order.order.delivery);
};

export const useOrderRange = () => {
  return useAppSelector((state) => state.order.order.range);
};

export const useOrderPayment = () => {
  return useAppSelector((state) => state.order.payment);
};

export const useOrderDiets = () => {
  return useAppSelector((state) => state.order.order.diets);
};

export const useOrderErrors = () => {
  return useAppSelector((state) => state.order.errors);
}

export const useOrderPersonalInfo = () => {
  return useAppSelector((state) => state.order.order.personalInfo);
}
