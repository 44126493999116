import { Button } from "../../common/button";
import styles from "./headerDropdown.module.css";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

type HeaderDropdownOptionType =
  | { label: ReactNode | string; to: string }
  | { label: ReactNode | string; onClick: () => void };

export interface HeaderDropdownProps {
  title: string | ReactNode;
  options: HeaderDropdownOptionType[];
  icon?: ReactNode;
}

export const HeaderDropdown: FunctionComponent<HeaderDropdownProps> = ({
  title,
  options,
  icon,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpen(false);
      }
    };
    const clickOutsideHandler = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener("keydown", handler);
    document.addEventListener("click", clickOutsideHandler);
    return () => {
      document.removeEventListener("keydown", handler);
      document.removeEventListener("click", clickOutsideHandler);
    };
  }, [open]);

  const handleSelect = (item: HeaderDropdownOptionType) => {
    setOpen(false);
    if ("onClick" in item) {
      item.onClick();
    }
  };

  return (
    <div className={styles.headerDropdown} ref={ref}>
      <Button text onClick={() => setOpen(!open)} icon={icon}>
        {title}
      </Button>
      {open ? (
        <div className={styles.headerDropdown__dropdownWrapper}>
          {options.map((item, index) => {
            return (
              <div key={index}>
                {"to" in item ? (
                  <Link to={item.to} onClick={() => handleSelect(item)}>
                    {item.label}
                  </Link>
                ) : (
                  <button onClick={() => handleSelect(item)}>
                    {item.label}
                  </button>
                )}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
