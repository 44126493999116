import { api } from "../api";
import { OrderPaymentType } from "../../slices/order/types";

export const paymentEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getOrderPayments: build.mutation<
      { orderId: string; paymentLink?: string; success: boolean },
      { id: string; payment: OrderPaymentType }
    >({
      query: ({ id, payment }) => {
        return {
          url: `payment-method/${id}`,
          method: "POST",
          body: payment,
        };
      },
      invalidatesTags: ["User", "OrderSingle", "Range"],
    }),
  }),
  overrideExisting: true,
});

export const { useGetOrderPaymentsMutation } = paymentEndpoints;
