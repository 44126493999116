import { useAppSelector } from "store";

export const useLocale = () => {
  const { locale } = useAppSelector((state) => state.common);
  return locale;
};

export const useToken = () => {
  const { token } = useAppSelector((state) => state.common);
  return token;
}
