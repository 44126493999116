import { PageSection } from "../../components/layout/pageSection";
import { Title } from "../../components/layout/title";
import { Translation } from "../../components/dictionary/translation";
import { FunctionComponent } from "react";
import { Loader } from "../../components/common/loader";
import { useTranslator } from "../../hooks/useTranslatable";
import { useGetPrivacyQuery } from "../../store/api/texts/endpoints";

export const Privacy: FunctionComponent = () => {
  const { data } = useGetPrivacyQuery();
  const translator = useTranslator();

  if (!data) {
    return <Loader />;
  }

  return (
    <PageSection
      title={
        <Title>
          <Translation k={"privacy.title"} />
        </Title>
      }
    >
      <div
        className={"text"}
        dangerouslySetInnerHTML={{ __html: translator(data.text) }}
      />
    </PageSection>
  );
};
