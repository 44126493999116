import { IDType } from "../../../types/common";
import { PriceType } from "../../../types/price";
import {TranslatableType} from "../../../types/translatable";

export enum DietTypes {
  Vegetarian = "v",
  Kcal750 = "7",
  Kcal1200 = "12",
  Kcal1500 = "15",
  Kcal1800 = "18",
  Kcal2500 = "25",
  Keto1500 = "k15",
  Keto2500 = "k25",
}

export type DietType = {
  id: IDType;
  image: string;
  price: PriceType;
  title: TranslatableType;
  type: DietTypes;
  description: TranslatableType;
};
