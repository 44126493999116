import {
  ContentCard,
  ContentCardItem,
} from "../../../../components/cards/content";
import { OptionGroup } from "../../../../components/layout/optionGroup";
import { RadioButton } from "../../../../components/common/radioButton";
import { PaymentMethods } from "../../../../types/payment";
import { Bank, Card, Wallet } from "../../../../components/icons";
import { useAppDispatch } from "../../../../store";
import { orderActions } from "../../../../store/slices/order/slice";
import { Translation } from "../../../../components/dictionary/translation";
import { useOrderPayment } from "../../../../store/slices/order/selectors";
import { IOrderPaymentMethods } from "../../../../types/order";
import { FunctionComponent } from "react";
import styles from "./paymentCard.module.css";

export interface PaymentCardProps {
  paymentMethods: IOrderPaymentMethods;
}

export const PaymentCard: FunctionComponent<PaymentCardProps> = ({
  paymentMethods,
}) => {
  const dispatch = useAppDispatch();
  const paymentMethod = useOrderPayment();

  const disclaimer = paymentMethod
    ? paymentMethods?.[paymentMethod?.method].disclaimer
    : null;

  return (
    <ContentCard title={<Translation k={`order.payment.title`} />}>
      <ContentCardItem>
        <OptionGroup
          row
          legend={<Translation k={`order.payment.method.title`} />}
        >
          {paymentMethods?.[PaymentMethods.bank].enabled ? (
            <RadioButton
              name={"payment-method"}
              value={PaymentMethods.bank}
              checked={paymentMethod?.method === PaymentMethods.bank}
              icon={<Bank />}
              description={<Translation k={`order.payment.bank.description`} />}
              onChange={() => {
                dispatch(orderActions.setPaymentMethod(PaymentMethods.bank));
              }}
            >
              <Translation k={`order.payment.bank`} />
            </RadioButton>
          ) : null}
          {paymentMethods?.[PaymentMethods.card].enabled ? (
            <RadioButton
              name={"payment-method"}
              value={PaymentMethods.card}
              checked={paymentMethod?.method === PaymentMethods.card}
              onChange={() => {
                dispatch(orderActions.setPaymentMethod(PaymentMethods.card));
              }}
              description={<Translation k={`order.payment.card.description`} />}
              icon={<Card />}
            >
              <Translation k={`order.payment.card`} />
            </RadioButton>
          ) : null}
          {paymentMethods?.[PaymentMethods.cash].enabled ? (
            <RadioButton
              name={"payment-method"}
              value={PaymentMethods.cash}
              checked={paymentMethod?.method === PaymentMethods.cash}
              onChange={() => {
                dispatch(orderActions.setPaymentMethod(PaymentMethods.cash));
              }}
              description={<Translation k={`order.payment.cash.description`} />}
              icon={<Wallet />}
            >
              <Translation k={`order.payment.cash`} />
            </RadioButton>
          ) : null}
        </OptionGroup>
      </ContentCardItem>

      {paymentMethod?.method === PaymentMethods.bank &&
      paymentMethods?.[PaymentMethods.bank].banks ? (
        <ContentCardItem>
          <OptionGroup
            row
            legend={<Translation k={`order.payment.bank.title`} />}
          >
            {paymentMethods?.[PaymentMethods.bank].banks.map((bank) => (
              <RadioButton
                name={"payment-bank"}
                value={bank.code}
                iconOnly
                noPadding
                checked={paymentMethod?.bank === bank.code}
                onChange={() => {
                  dispatch(orderActions.setPaymentBank(bank.code));
                }}
              >
                <img
                  className={styles.paymentCard__bankImage}
                  src={bank.imageUrl}
                  alt={bank.name}
                />
              </RadioButton>
            ))}
          </OptionGroup>
        </ContentCardItem>
      ) : null}
      {disclaimer ? (
        <ContentCardItem>
          <div className={`text`}>
            <p
              dangerouslySetInnerHTML={{
                __html: disclaimer,
              }}
            />
          </div>
        </ContentCardItem>
      ) : null}
    </ContentCard>
  );
};
