import { api } from "../api";
import {TranslationResponseTypes} from "./types";

export const commonEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    getTranslations: build.query<TranslationResponseTypes, void>({
      query: () => {
        return {
          url: `translations`,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetTranslationsQuery } = commonEndpoint;
