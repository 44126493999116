import { FunctionComponent, useMemo, useState } from "react";
import { ContentCard } from "../../components/cards/content";
import { Translation } from "../../components/dictionary/translation";
import { FormGrid, FormItem } from "../../components/layout/formGrid";
import { Input } from "../../components/common/input";
import { Button } from "../../components/common/button";
import {
  useForgotPasswordMutation,
} from "../../store/api/login/endpoints";
import { ErrorType } from "../../types/errors";
import { Navigate } from "react-router-dom";
import { useToken } from "../../store/slices/common/selectors";
import { InfoCard } from "../../components/cards/info";

export const ForgotPassword: FunctionComponent = () => {
  const token = useToken();
  const [email, setEmail] = useState("");
  const [sendEmail, { data, isLoading, error }] = useForgotPasswordMutation();

  const errors = useMemo(
    () =>
      (error && "data" in error ? error.data : undefined) as
        | ErrorType<"email">
        | undefined,
    [error]
  );

  if (token) {
    return <Navigate to={"/profile"} replace />;
  }

  return (
    <ContentCard title={<Translation k={"forgotPassword.title"} />} narrow>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          sendEmail({ email });
        }}
      >
        <FormGrid>
          <FormItem span={2}>
            <div className={"text"}>
              <Translation k={"forgotPassword.text"} />
            </div>
          </FormItem>
          <FormItem span={2}>
            <Input
              name={"email"}
              type={"email"}
              label={<Translation k={`common.email`} />}
              autoComplete={"email"}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              error={errors?.errors?.["email"]}
            />
          </FormItem>
          <FormItem span={2} footer>
            <Button type={"submit"} loading={isLoading} disabled={!email}>
              <Translation k={"forgotPassword.sendEmail"} />
            </Button>
          </FormItem>
          {data?.message ? (
            <FormItem span={2}>
              <InfoCard>{data.message}</InfoCard>
            </FormItem>
          ) : null}
        </FormGrid>
      </form>
    </ContentCard>
  );
};
