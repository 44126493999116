import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Locales } from "../../../types/locale";

const storedToken = localStorage.getItem("token");
const storedLocale = localStorage.getItem("locale") as Locales;

const initialState: {
  token: string | null;
  locale: Locales;
} = {
  token: storedToken || null,
  locale: storedLocale || Locales.en,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<Locales>) => {
      state.locale = action.payload;
      localStorage.setItem("locale", action.payload);
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
      if (action.payload) {
        localStorage.setItem("token", action.payload);
      } else {
        localStorage.removeItem("token");
      }
    },
  },
});

export const commonActions = commonSlice.actions;
