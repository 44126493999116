import { FunctionComponent } from "react";
import { TranslatableType } from "../../../types/translatable";
import { useLocale } from "../../../store/slices/common/selectors";

export const Translatable: FunctionComponent<{ value: TranslatableType }> = ({
  value,
}) => {
  const locale = useLocale();

  return <>{value?.[locale] || ""}</>;
};
