import { FormGrid, FormItem } from "../../../../components/layout/formGrid";
import { Input } from "../../../../components/common/input";
import { Translation } from "../../../../components/dictionary/translation";
import { TextArea } from "../../../../components/common/textarea";
import { InfoCard } from "../../../../components/cards/info";
import {
  useOrderDelivery,
  useOrderErrors,
} from "../../../../store/slices/order/selectors";
import { useAppDispatch } from "../../../../store";
import { orderActions } from "../../../../store/slices/order/slice";
import { Locations } from "../../../../store/slices/order/types";

export const AddressForm = () => {
  const { comment } = useOrderDelivery();
  const dispatch = useAppDispatch();
  const {
    location,
    address: { city, address },
  } = useOrderDelivery();
  const errors = useOrderErrors();

  return (
    <FormGrid>
      <FormItem span={2}>
        <Input
          label={<Translation k={"common.city"} />}
          autoComplete={'city'}
          value={city}
          onChange={(event) =>
            dispatch(orderActions.setAddressCity(event.target.value))
          }
          error={errors?.errors?.["delivery.address.city"]}
        />
      </FormItem>
      <FormItem span={2}>
        <Input
          label={<Translation k={"common.address"} />}
          value={address}
          onChange={(event) =>
            dispatch(orderActions.setAddress(event.target.value))
          }
          error={errors?.errors?.["delivery.address.address"]}
        />
      </FormItem>
      <FormItem span={2}>
        <TextArea
          label={<Translation k={"order.delivery.location.comment"} />}
          value={comment}
          onChange={(event) =>
            dispatch(orderActions.setComment(event.target.value))
          }
          error={errors?.errors?.["delivery.comment"]}
        />
      </FormItem>
      {location === Locations.Other ? (
        <FormItem span={2}>
          <InfoCard>
            <Translation k={"order.delivery.location.warning"} />
          </InfoCard>
        </FormItem>
      ) : null}
    </FormGrid>
  );
};
