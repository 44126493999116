import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import styles from "./layout.module.css";
import { Header } from "../../header/header";
import { MenuModal } from "../../controlled/menuModal";
import { Footer } from "../footer";
import { useGetTranslationsQuery } from "../../../store/api/common/endpoints";
import { Loader } from "../../common/loader";

export const Layout: FunctionComponent = () => {
  const { data } = useGetTranslationsQuery();

  return (
    <div className={styles.layout}>
      <Header />
      {data ? (
        <main className={styles.layout__content}>
          <div className={styles.layout__container}>
            <Outlet />
          </div>
        </main>
      ) : (
        <Loader />
      )}
      <Footer />
      <MenuModal />
    </div>
  );
};
