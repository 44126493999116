import {Link, LinkProps as RouterLinkProps} from "react-router-dom";
import { FunctionComponent } from "react";
import styles from "./link.module.css";

export interface LinkInlineProps extends RouterLinkProps {}

export const LinkInline: FunctionComponent<LinkInlineProps> = ({
  to,
  children,
  className,
...rest
}) => {
  return (
    <Link to={to} className={`${styles.link} ${className}`} {...rest}>
      {children}
    </Link>
  );
};
