export const Utensils = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79954 13.1283L10.6295 10.2983L3.60954 3.28833C2.04954 4.84833 2.04954 7.37833 3.60954 8.94833L7.79954 13.1283V13.1283ZM14.5795 11.3183C16.1095 12.0283 18.2595 11.5283 19.8495 9.93833C21.7595 8.02833 22.1295 5.28833 20.6595 3.81833C19.1995 2.35833 16.4595 2.71833 14.5395 4.62833C12.9495 6.21833 12.4495 8.36833 13.1595 9.89833L3.39954 19.6583L4.80954 21.0683L11.6995 14.1983L18.5795 21.0783L19.9895 19.6683L13.1095 12.7883L14.5795 11.3183V11.3183Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Delivery = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7C19 5.9 18.1 5 17 5H14V7H17V9.65L13.52 14H10V9H6C3.79 9 2 10.79 2 13V16H4C4 17.66 5.34 19 7 19C8.66 19 10 17.66 10 16H14.48L19 10.35V7ZM4 14V13C4 11.9 4.9 11 6 11H8V14H4ZM7 17C6.45 17 6 16.55 6 16H8C8 16.55 7.55 17 7 17Z"
        fill="currentColor"
      />
      <path d="M10 6H5V8H10V6Z" fill="currentColor" />
      <path
        d="M19 13C17.34 13 16 14.34 16 16C16 17.66 17.34 19 19 19C20.66 19 22 17.66 22 16C22 14.34 20.66 13 19 13ZM19 17C18.45 17 18 16.55 18 16C18 15.45 18.45 15 19 15C19.55 15 20 15.45 20 16C20 16.55 19.55 17 19 17Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Info = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Tick = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.6 7.9288L1.975 5.3038C1.6825 5.0113 1.2175 5.0113 0.925002 5.3038C0.632502 5.5963 0.632502 6.0613 0.925002 6.3538L4.0675 9.4963C4.36 9.7888 4.8325 9.7888 5.125 9.4963L13.075 1.5538C13.3675 1.2613 13.3675 0.796299 13.075 0.503799C12.7825 0.211299 12.3175 0.211299 12.025 0.503799L4.6 7.9288Z" />
    </svg>
  );
};

export const Bank = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 11.8713V15.8713C4.5 16.7013 5.17 17.3713 6 17.3713C6.83 17.3713 7.5 16.7013 7.5 15.8713V11.8713C7.5 11.0413 6.83 10.3713 6 10.3713C5.17 10.3713 4.5 11.0413 4.5 11.8713ZM10.5 11.8713V15.8713C10.5 16.7013 11.17 17.3713 12 17.3713C12.83 17.3713 13.5 16.7013 13.5 15.8713V11.8713C13.5 11.0413 12.83 10.3713 12 10.3713C11.17 10.3713 10.5 11.0413 10.5 11.8713ZM4 22.3713H20C20.83 22.3713 21.5 21.7013 21.5 20.8713C21.5 20.0413 20.83 19.3713 20 19.3713H4C3.17 19.3713 2.5 20.0413 2.5 20.8713C2.5 21.7013 3.17 22.3713 4 22.3713ZM16.5 11.8713V15.8713C16.5 16.7013 17.17 17.3713 18 17.3713C18.83 17.3713 19.5 16.7013 19.5 15.8713V11.8713C19.5 11.0413 18.83 10.3713 18 10.3713C17.17 10.3713 16.5 11.0413 16.5 11.8713ZM11.07 1.86125L3.17 6.02125C2.76 6.23125 2.5 6.66125 2.5 7.12125C2.5 7.81125 3.06 8.37125 3.75 8.37125H20.26C20.94 8.37125 21.5 7.81125 21.5 7.12125C21.5 6.66125 21.24 6.23125 20.83 6.02125L12.93 1.86125C12.35 1.55125 11.65 1.55125 11.07 1.86125V1.86125Z" />
    </svg>
  );
};

export const Wallet = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 16V8C9.5 6.9 10.39 6 11.5 6H20.5V5C20.5 3.9 19.6 3 18.5 3H4.5C3.39 3 2.5 3.9 2.5 5V19C2.5 20.1 3.39 21 4.5 21H18.5C19.6 21 20.5 20.1 20.5 19V18H11.5C10.39 18 9.5 17.1 9.5 16ZM12.5 8C11.95 8 11.5 8.45 11.5 9V15C11.5 15.55 11.95 16 12.5 16H21.5V8H12.5ZM15.5 13.5C14.67 13.5 14 12.83 14 12C14 11.17 14.67 10.5 15.5 10.5C16.33 10.5 17 11.17 17 12C17 12.83 16.33 13.5 15.5 13.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Card = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM19 18H5C4.45 18 4 17.55 4 17V12H20V17C20 17.55 19.55 18 19 18ZM20 8H4V6H20V8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Bin = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 19.5C6 20.6 6.9 21.5 8 21.5H16C17.1 21.5 18 20.6 18 19.5V9.5C18 8.4 17.1 7.5 16 7.5H8C6.9 7.5 6 8.4 6 9.5V19.5ZM18 4.5H15.5L14.79 3.79C14.61 3.61 14.35 3.5 14.09 3.5H9.91C9.65 3.5 9.39 3.61 9.21 3.79L8.5 4.5H6C5.45 4.5 5 4.95 5 5.5C5 6.05 5.45 6.5 6 6.5H18C18.55 6.5 19 6.05 19 5.5C19 4.95 18.55 4.5 18 4.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Plus = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 13H13.5V18C13.5 18.55 13.05 19 12.5 19C11.95 19 11.5 18.55 11.5 18V13H6.5C5.95 13 5.5 12.55 5.5 12C5.5 11.45 5.95 11 6.5 11H11.5V6C11.5 5.45 11.95 5 12.5 5C13.05 5 13.5 5.45 13.5 6V11H18.5C19.05 11 19.5 11.45 19.5 12C19.5 12.55 19.05 13 18.5 13Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Close = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3 5.71C17.91 5.32 17.28 5.32 16.89 5.71L12 10.59L7.11 5.7C6.72 5.31 6.09 5.31 5.7 5.7C5.31 6.09 5.31 6.72 5.7 7.11L10.59 12L5.7 16.89C5.31 17.28 5.31 17.91 5.7 18.3C6.09 18.69 6.72 18.69 7.11 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.11C18.68 6.73 18.68 6.09 18.3 5.71Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Profile = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 19.2C9.5 19.2 7.29 17.92 6 15.98C6.03 13.99 10 12.9 12 12.9C13.99 12.9 17.97 13.99 18 15.98C16.71 17.92 14.5 19.2 12 19.2Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Edit = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.99875 17.4612V20.5012C2.99875 20.7812 3.21875 21.0012 3.49875 21.0012H6.53875C6.66875 21.0012 6.79875 20.9512 6.88875 20.8512L17.8087 9.94125L14.0587 6.19125L3.14875 17.1012C3.04875 17.2012 2.99875 17.3212 2.99875 17.4612ZM20.7087 7.04125C21.0987 6.65125 21.0987 6.02125 20.7087 5.63125L18.3687 3.29125C17.9787 2.90125 17.3487 2.90125 16.9587 3.29125L15.1287 5.12125L18.8787 8.87125L20.7087 7.04125Z"
        fill="#2B2B2B"
      />
    </svg>
  );
};
