import { FunctionComponent, InputHTMLAttributes } from "react";
import { v4 as uuid } from "uuid";
import styles from "./checkbox.module.css";
import { Tick } from "../../icons";
import { ErrorPropType, Errors } from "../errors";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: ErrorPropType;
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  children,
  checked,
  id = uuid(),
  error,
  ...rest
}) => {
  return (
    <div className={styles.checkbox}>
      <div className={styles.checkbox__container}>
        <input
          className={styles.checkbox__input}
          type={"checkbox"}
          checked={checked}
          id={id}
          {...rest}
        />
        <label className={styles.checkbox__row} htmlFor={id}>
          <span className={styles.checkbox__tick}>
            <Tick />
          </span>
          <span className={styles.checkbox__label}>{children}</span>
        </label>
      </div>
      <Errors error={error} />
    </div>
  );
};
