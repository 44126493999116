import { FunctionComponent, InputHTMLAttributes, ReactNode } from "react";
import { v4 as uuid } from "uuid";
import styles from "./radioButon.module.css";
import { useModifiers } from "../../../hooks/useModifiers";

export interface RadioButtonProps
  extends InputHTMLAttributes<HTMLInputElement> {
  description?: ReactNode;
  icon?: ReactNode;
  iconOnly?: boolean;
  noPadding?: boolean;
}

export const RadioButton: FunctionComponent<RadioButtonProps> = ({
  checked,
  children,
  className,
  id = uuid(),
  description,
  icon,
  iconOnly,
  noPadding,
  ...rest
}) => {
  const mods = useModifiers(styles, "radioButton", { noPadding });

  return (
    <div className={`${styles.radioButton} ${mods}`}>
      <input
        className={styles.radioButton__input}
        type={"radio"}
        checked={checked}
        id={id}
        {...rest}
      />
      <label className={styles.radioButton__card} htmlFor={id}>
        {!iconOnly ? (
          <>
            <span className={styles.radioButton__row}>
              {icon ? (
                <span className={styles.radioButton__icon}>{icon}</span>
              ) : (
                <span className={styles.radioButton__tick} />
              )}
              <span className={styles.radioButton__labelContainer}>
                <span className={styles.radioButton__label}>{children}</span>
                {description && icon ? (
                  <span
                    className={`${styles.radioButton__description} ${styles.radioButton__description_icon}`}
                  >
                    {description}
                  </span>
                ) : null}
              </span>
            </span>
            {description && !icon ? (
              <span className={styles.radioButton__description}>
                {description}
              </span>
            ) : null}
          </>
        ) : (
          <div className={styles.radioButton__iconOnlyContainer}>
            {children}
          </div>
        )}
      </label>
    </div>
  );
};
