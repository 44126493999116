import { FunctionComponent, HTMLAttributes } from "react";
import styles from "./infoCard.module.css";
import { Info } from "../../icons";
import { useModifiers } from "../../../hooks/useModifiers";

export interface InfoCardProps extends HTMLAttributes<HTMLDivElement> {
  warning?: boolean;
  danger?: boolean;
}

export const InfoCard: FunctionComponent<InfoCardProps> = ({
  children,
  className,
  warning,
  danger,
  ...rest
}) => {
  const mods = useModifiers(styles, "infoCard", {
    warning,
    danger,
  });

  return (
    <div className={`${styles.infoCard} ${mods} ${className}`} {...rest}>
      <div className={styles.infoCard__icon}>
        <Info />
      </div>
      {children}
    </div>
  );
};
