import { api } from "../api";
import { UserDataResponse } from "./types";

export const userEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<UserDataResponse, void>({
      query: () => {
        return {
          url: `user-data`,
        };
      },
      providesTags: ["User"],
    }),
    submitPersonalInfo: build.mutation<
      any,
      { name: string | null; phone: string | null }
    >({
      query: (personalInfo) => {
        return {
          url: `personal-info`,
          method: "POST",
          body: personalInfo,
        };
      },
      invalidatesTags: ["User"],
    }),
  }),
  overrideExisting: true,
});

export const { useGetUserQuery, useSubmitPersonalInfoMutation } = userEndpoint;
