import { FunctionComponent, HTMLAttributes } from "react";
import { useModifiers } from "../../../hooks/useModifiers";
import styles from "./title.module.css";

export interface TitleProps extends HTMLAttributes<HTMLDivElement> {
  center?: boolean;
  noMargin?: boolean;
}

export const Title: FunctionComponent<TitleProps> = ({
  children,
  className,
  center,
  noMargin,
  ...rest
}) => {
  const mods = useModifiers(styles, "title", { center });

  return (
    <div className={`${styles.title} ${mods} ${className}`} {...rest}>
      <h1 className={`h1 ${noMargin ? "h1_noMargin" : ""}`}>{children}</h1>
    </div>
  );
};
