import { PaymentCard } from "../../order/components/paymentCard";
import { ContentCard } from "../../../components/cards/content";
import { SumTable } from "../../../components/layout/sumTable";
import { Translation } from "../../../components/dictionary/translation";
import { ActionGroup } from "../../../components/layout/actionGroup";
import { Button } from "../../../components/common/button";
import { usePriceFormatter } from "../../../hooks/useFormattedPrice";
import { useGetOrderPaymentsMutation } from "../../../store/api/payment/endpoints";
import { FunctionComponent, useMemo } from "react";
import { OrderStatus, OrderType } from "../../../types/order";
import { useOrderPayment } from "../../../store/slices/order/selectors";
import { Navigate } from "react-router-dom";
import { PaymentMethods } from "../../../types/payment";
import { PriceType } from "../../../types/price";
import { addPrices } from "../../../helpers/prices";

export const OrderPaymentBlock: FunctionComponent<{ order: OrderType }> = ({
  order,
}) => {
  const priceFormatter = usePriceFormatter();
  const [pay, { data: paymentResult, isLoading }] =
    useGetOrderPaymentsMutation();
  const payment = useOrderPayment();

  const paymentCommission = useMemo((): PriceType | undefined => {
    if (payment?.method && order.paymentMethods[payment.method]) {
      return order.paymentMethods[payment.method].commission;
    }
  }, [order.paymentMethods, payment]);

  const handlePayment = () => {
    if (!payment) return;
    pay({ id: order.id, payment });
  };

  if (paymentResult && paymentResult.success) {
    if (paymentResult.paymentLink) {
      window.location.href = paymentResult.paymentLink;
    } else {
      return <Navigate to={`/order/payment-success?method=cache`} replace />;
    }
  }

  let sumTableItems = [
    {
      label: <Translation k={"order.submit.mealSum"} />,
      value: priceFormatter(order.sum.mealSum),
    },
  ];

  sumTableItems.push({
    label: <Translation k={"order.submit.deliverySum"} />,
    value: priceFormatter(order.sum.deliverySum),
  });

  if (paymentCommission && paymentCommission.value > 0) {
    sumTableItems.push({
      label: <Translation k={"order.submit.paymentCommission"} />,
      value: priceFormatter(paymentCommission),
    });
  }

  sumTableItems.push({
    label: <Translation k={"order.submit.totalSum"} />,
    value: paymentCommission
      ? priceFormatter(addPrices(order.sum.total, paymentCommission))
      : priceFormatter(order.sum.total),
  });

  return (
    <>
      {order.status === OrderStatus.PendingPayment ||
      order.status === OrderStatus.PaymentFailed ? (
        <PaymentCard paymentMethods={order.paymentMethods} />
      ) : null}
      <ContentCard>
        <SumTable items={sumTableItems} />
        {order.status === OrderStatus.PendingPayment ||
        order.status === OrderStatus.PaymentFailed ? (
          <ActionGroup end>
            <Button
              loading={isLoading}
              disabled={
                !payment ||
                (payment.method === PaymentMethods.bank && !payment.bank)
              }
              onClick={() => handlePayment()}
            >
              <Translation k={"common.pay"} />
            </Button>
          </ActionGroup>
        ) : null}
      </ContentCard>
    </>
  );
};
