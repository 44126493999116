import { api } from "../api";
import { DeliveryResponseType } from "./types";

export const deliveryEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    getDelivery: build.query<DeliveryResponseType, void>({
      query: () => {
        return {
          url: `delivery`,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetDeliveryQuery } = deliveryEndpoint;
