import { FunctionComponent, useMemo } from "react";
import { ContentCard } from "../../components/cards/content";
import { Translation } from "../../components/dictionary/translation";
import { FormGrid, FormItem } from "../../components/layout/formGrid";
import { Input } from "../../components/common/input";
import { Button, Link } from "../../components/common/button";
import { useResetPasswordMutation } from "../../store/api/login/endpoints";
import { ErrorType } from "../../types/errors";
import { InfoCard } from "../../components/cards/info";
import { Navigate, useParams } from "react-router-dom";
import { useResetPasswordForm } from "../../store/slices/login/selectors";
import { useAppDispatch } from "../../store";
import { loginActions } from "../../store/slices/login/slice";

export const ResetPassword: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const form = useResetPasswordForm();
  const [resetPassword, { data, isLoading, error, isSuccess }] =
    useResetPasswordMutation();
  const { token } = useParams();

  const errors = useMemo(
    () =>
      (error && "data" in error ? error.data : undefined) as
        | ErrorType<"email" | "password" | "password_confirmation" | "token">
        | undefined,
    [error]
  );

  if (!token) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <ContentCard
      title={<Translation k={"forgotPassword.resetPassword"} />}
      narrow
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          resetPassword({ ...form, token });
        }}
      >
        <FormGrid>
          <FormItem span={2}>
            <div className={"text"}>
              <Translation k={"forgotPassword.resetPasswordText"} />
            </div>
          </FormItem>
          <FormItem span={2}>
            <Input
              name={"email"}
              type={"email"}
              label={<Translation k={`common.email`} />}
              autoComplete={"email"}
              value={form.email}
              onChange={(event) =>
                dispatch(loginActions.setResetPasswordEmail(event.target.value))
              }
              error={errors?.errors?.["email"]}
            />
          </FormItem>
          <FormItem span={2}>
            <Input
              name={"password"}
              type={"password"}
              label={<Translation k={`common.password`} />}
              autoComplete={"none"}
              value={form.password}
              onChange={(event) =>
                dispatch(
                  loginActions.setResetPasswordPassword(event.target.value)
                )
              }
              error={errors?.errors?.["password"]}
            />
          </FormItem>
          <FormItem span={2}>
            <Input
              name={"password_confirmation"}
              type={"password"}
              label={<Translation k={`common.passwordConfirmation`} />}
              autoComplete={"none"}
              value={form.password_confirmation}
              onChange={(event) =>
                dispatch(
                  loginActions.setResetPasswordPasswordConfirmation(
                    event.target.value
                  )
                )
              }
              error={errors?.errors?.["password_confirmation"]}
            />
          </FormItem>
          {!isSuccess ? (
            <FormItem span={2} footer>
              <Button type={"submit"} loading={isLoading}>
                <Translation k={"forgotPassword.reset"} />
              </Button>
            </FormItem>
          ) : null}
          {data?.message ? (
            <FormItem span={2}>
              <InfoCard>{data.message}</InfoCard>
            </FormItem>
          ) : null}
          {errors?.errors?.["token"] ? (
            <FormItem span={2}>
              <InfoCard danger>{errors?.errors?.["token"]}</InfoCard>
            </FormItem>
          ) : null}
          {isSuccess ? (
            <FormItem span={2} footer>
              <Link to={"/login"}>
                <Translation k={"forgotPassword.toLogin"} />
              </Link>
            </FormItem>
          ) : null}
        </FormGrid>
      </form>
    </ContentCard>
  );
};
