import styles from "./actionGroup.module.css";
import { FunctionComponent, HTMLAttributes } from "react";
import { useModifiers } from "../../../hooks/useModifiers";

export interface ActionGroupProps extends HTMLAttributes<HTMLDivElement> {
  end?: boolean;
}

export const ActionGroup: FunctionComponent<ActionGroupProps> = ({
  end,
  children,
}) => {
  const mods = useModifiers(styles, "actionGroup", { end });

  return <div className={`${styles.actionGroup} ${mods}`}>{children}</div>;
};
