import React, { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "components/layout/layout";
import { HomePage } from "pages/home";
import { OrderPage } from "./pages/order";
import { ConfirmEmail } from "./pages/confirmEmail";
import { ConfirmEmailContent } from "./pages/confirmEmail/confirmEmailContent";
import { Login } from "./pages/login";
import { OrderSuccess } from "./pages/orderSuccess";
import { OrderSingle } from "./pages/orderSingle";
import { ProfilePage } from "./pages/profile";
import { ForgotPassword } from "./pages/forgotPassword";
import { Error } from "./pages/error";
import { ResetPassword } from "./pages/resetPassword";
import { PaymentSuccess } from "./pages/paymentSucess";
import { PaymentFail } from "./pages/paymentFail";
import { Terms } from "./pages/terms";
import { Privacy } from "./pages/privacy";
import { PaymentResultPage } from "./pages/paymentResult";

export const App: FunctionComponent = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path={"profile"} element={<ProfilePage />} />
        <Route path={"order"}>
          <Route index element={<OrderPage />} />
          <Route path={"success"} element={<OrderSuccess />} />
          <Route path={"payment-success"} element={<PaymentSuccess />} />
          <Route path={"payment-fail"} element={<PaymentFail />} />
          <Route path={"payment-result"} element={<PaymentResultPage />} />
          <Route path={":id"} element={<OrderSingle />} />
        </Route>
        <Route path={"email-confirm"} element={<ConfirmEmail />}>
          <Route index element={<Navigate to={"/"} replace />} />
          <Route path=":id" element={<ConfirmEmailContent />} />
        </Route>
        <Route path={"login"} element={<Login />} />
        <Route path={"password"}>
          <Route path={"forgot"} element={<ForgotPassword />} />
          <Route path={"reset"}>
            <Route index element={<Navigate to={"/"} replace />} />
            <Route path={":token"} element={<ResetPassword />} />
          </Route>
        </Route>
        <Route path={"terms"} element={<Terms />} />
        <Route path={"privacy"} element={<Privacy />} />
        <Route path={"*"} element={<Error />} />
      </Route>
    </Routes>
  );
};
