import { WeekSubset } from "../../../types/calendar";
import { ErrorType } from "../../../types/errors";
import {PaymentMethods} from "../../../types/payment";
// import { PaymentMethods } from "../../../types/payment";

export enum Locations {
  Riga = "Riga",
  Jurmala = "Jurmala",
  Other = "other",
}

export type OrderFormType = {
  diets: { id: string; comment: string | null }[];
  range: {
    weeks: number;
    weekSubset: WeekSubset;
  };
  delivery: {
    location: Locations | null;
    address: {
      city: string;
      address: string;
    };
    comment: string;
  };
  personalInfo: {
    name: string;
    email: string;
    phone: string;
    password: string;
    password_confirmation: string;
    agree: boolean;
  };
  // payment: {
  //   method: PaymentMethods;
  //   bank: string;
  // };
};

export type OrderPaymentType = {
  method: PaymentMethods;
  bank?: string | null;
}

export type OrderErrorType = ErrorType<
  | "delivery.comment"
  | "delivery.location"
  | "delivery.address.city"
  | "delivery.address.address"
  | "personalInfo.name"
  | "personalInfo.email"
  | "personalInfo.phone"
  | "personalInfo.password"
  | "personalInfo.password_confirmation"
  | "personalInfo.agree"
>;
